import BrainActivity from "../components/waiting-room/BrainActivity";
import "../styles/WaitingRoom.component.scss";
import ProfileDropdown from "../components/ProfileDropdown";
import RoomMessageCard from "../components/RoomMessageCard";
import { NavLink, useParams } from "react-router-dom";
import { useEffect } from "react";
import { ViewLayoutComponent } from "../components/ViewLayout.component";
import { useHttpClient } from "../utils/http.utils";
import { LordIcon } from "../components/icons/LordIcon";
import { CiCircleInfo } from "react-icons/ci";
import { TemporaryRecords } from "../components/TemporaryRecords";

const WaitingRoom = () => {
    const { id } = useParams();
    const { listRoomPostsApi } = useHttpClient();

    useEffect(() => {
        listRoomPostsApi(id!);
    }, []);

    return (
        <ViewLayoutComponent>
            <div className="waiting-room">
                <div className="grid-column bg-white pt-[12px]">
                    <ProfileDropdown dropDownStyle={{ boxShadow: "none" }} />
                    {/* <WaitingLeftPane /> */}
                    <div className="pt-[20px] temporary-record-holder">
                        <TemporaryRecords />
                    </div>
                </div>
                <div className="main-grid grid-column">
                    <div className="main-grid-wrapper">
                        <div className="waiting-room-header-box">
                            <div className="header-box">
                                <h2 className="title">
                                    VIP Rooms.
                                    <p className="title-description">
                                        Welcome to Neo Al's VIP room. Stay up to
                                        date with AI while we roll out Neo.
                                    </p>
                                </h2>
                                <p className="subtitle">
                                    <span className="name">
                                        Montague Adameve
                                    </span>
                                    <span className="arin-text">arin</span>
                                    ,&nbsp;
                                    <span className="name">Paul Snow</span>
                                    ,&nbsp;
                                    <span className="name">James Duchenne</span>
                                    &nbsp; & 235 others.
                                </p>
                            </div>
                            <div className="line w-full">
                                <div className="line-inner"></div>
                            </div>
                            <div className="actions-box">
                                <NavLink to={"/"}>
                                    <button className="button">
                                        <LordIcon
                                            src="/lord-icons/stack.json"
                                            trigger="hover"
                                            colors={{
                                                primary: "#121331",
                                                secondary: "#333"
                                            }}
                                            stroke={40}
                                            size={30}
                                        />
                                    </button>
                                </NavLink>

                                <button className="button">
                                    <CiCircleInfo
                                        className="text-gray-600"
                                        size="22px"
                                    />
                                </button>
                            </div>
                        </div>
                        {/* <div className="waiting-room-header-content lg-view">
                        <NavLink to={'/'}>
                            <button className="back-button">
                                <LordIcon
                                    src="/lord-icons/stack.json"
                                    trigger="hover"
                                    colors={{
                                        primary: '#121331',
                                        secondary: '#333',
                                    }}
                                    stroke={40}
                                    size={30}
                                />
                            </button>
                        </NavLink>
                        <div className="divider"></div>
                        <p className="">
                            with montague adameve (arin), paul snow, james
                            duchenne & 235 others.
                        </p>
                    </div> */}
                        <div className="waiting-room-body-content">
                            {/* <RoomMessageCard
                                mode="info"
                                text={[
                                    "Welcome to Neo Al's VIP room. Stay up to date with Al while we roll out Neo. #FollowTheWhiteRabbit",
                                ]}
                            /> */}

                            <RoomMessageCard
                                text={[
                                    `The general problem of simulating (or creating) intelligence has been broken down into sub-problems. These consist of particular traits or capabilities that researchers expect an intelligent system to display. 
                        The traits described below have received the most attention and 
                        cover the scope of Al research. The general problem of simulating (or creating)
                        intelligence has been broken down into sub-problems. These consist of particular traits or capabilities that
                        researchers expect an Intelligent system to display. The traits described below have received the most attention and 
                        cover the scope of Al research. The general problem of simulating (or creating) intelligence has been broken down into
                        sub-problems. These consist of particular traits of capabilities that researchers expect an intelligent system..
                        `
                                ]}
                                isInGroup={true}
                            />
                            {/* <RoomMessageInput
                                roomId={id}
                                showActions={["subscribe"]}
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="grid-column bg-white activity-grid pt-[12px]">
                    <BrainActivity />
                </div>
            </div>
        </ViewLayoutComponent>
    );
};

export default WaitingRoom;
