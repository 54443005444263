import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {
    type AutoInteractionMessage,
    type CollaborationApp,
    type CollaborationGroup
} from "../../models/collaboration.model";

export interface CollaborateState {
    viewMode: "start" | "initialize";
    isInitializing: boolean;
    isInitialized: boolean;
    enableInput: boolean;
    isLoading: boolean;
    autoInteractionId: string;
    socketRoom: string;
    persona: { name: string };
    socketConnected: boolean;
    collaborationAppId: string;
    initializationMessage: string;
    autoInteractionMessages: AutoInteractionMessage[];
    collaborationGroups: CollaborationGroup[];
    selectedCollaborationApp: CollaborationApp | null;
}

const initialState: CollaborateState = {
    isInitializing: false,
    viewMode: "start", // "start" | "initialize"
    isInitialized: false,
    enableInput: true,
    isLoading: false,
    autoInteractionId: "",
    socketRoom: "",
    socketConnected: false,
    collaborationAppId: "",
    initializationMessage: "",
    persona: { name: "" },
    autoInteractionMessages: [
        // {
        //     message: "What are your goals?",
        //     message_format: "decision"
        // },
        // {
        //     message_format: "input",
        //     message: [
        //         "I want to be a better person",
        //         "I want to know how to run a business",
        //         "I want to create a hedge fund"
        //     ]
        // }
    ],
    collaborationGroups: [],
    selectedCollaborationApp: null
};

const collaborateSlice = createSlice({
    name: "collaborate",
    initialState,
    reducers: {
        setCollaborateViewMode(
            state,
            action: PayloadAction<"start" | "initialize">
        ) {
            state.viewMode = action.payload;
        },
        setIsCollaborateLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setEnableCollaborationInput(state, action: PayloadAction<boolean>) {
            state.enableInput = action.payload;
        },
        setIsAutoInteractionInitializing(
            state,
            action: PayloadAction<boolean>
        ) {
            state.isInitializing = action.payload;
        },
        setIsAutoInteractionInitialized(state, action: PayloadAction<boolean>) {
            state.isInitialized = action.payload;
        },
        setSocketConnected(state, action: PayloadAction<boolean>) {
            state.socketConnected = action.payload;
        },
        setSocketRoom(state, action: PayloadAction<string>) {
            state.socketRoom = action.payload;
        },
        setCollaborationAppId(state, action: PayloadAction<string>) {
            state.collaborationAppId = action.payload;
        },
        setAutoInteractionId(state, action: PayloadAction<string>) {
            state.autoInteractionId = action.payload;
        },
        setAutoInteractionMessage(
            state,
            action: PayloadAction<AutoInteractionMessage[]>
        ) {
            state.autoInteractionMessages = action.payload;
        },
        setCollaborateInitializationMessage(
            state,
            action: PayloadAction<string>
        ) {
            state.initializationMessage = action.payload;
        },
        setCollaborationPersona(
            state,
            action: PayloadAction<{ name: string }>
        ) {
            state.persona = action.payload;
        },
        addAutoInteractionMessage(
            state,
            action: PayloadAction<AutoInteractionMessage>
        ) {
            const idExists = state.autoInteractionMessages.some(
                object => object.id === action.payload.id
            );

            if (!idExists) {
                state.autoInteractionMessages = [
                    ...state.autoInteractionMessages,
                    action.payload
                ];
            }
        },
        addCollaborationGroup(
            state,
            action: PayloadAction<CollaborationGroup>
        ) {
            state.collaborationGroups = [
                ...state.collaborationGroups,
                action.payload
            ];
        },
        addCollaborationGroupApp(
            state,
            action: PayloadAction<{ groupId: string; app: CollaborationApp }>
        ) {
            const { groupId, app } = action.payload;
            const groupIndex = state.collaborationGroups.findIndex(
                group => group.id === groupId
            );

            if (groupIndex > -1) {
                state.collaborationGroups[groupIndex].apps = [
                    ...state.collaborationGroups[groupIndex].apps,
                    app
                ];
            }
        },
        clearActivityInteractionMessages(state) {
            state.autoInteractionMessages =
                state.autoInteractionMessages.filter(message => {
                    return (
                        message.message_format === "user" ||
                        message.message_format === "general" ||
                        message.message_format === "ai"
                    );
                });
        },
        updateAutoInteractionMessage(
            state,
            action: PayloadAction<AutoInteractionMessage>
        ) {
            state.autoInteractionMessages = state.autoInteractionMessages.map(
                object => {
                    if (object.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return object;
                    }
                }
            );
        }
    }
});

export const {
    setIsAutoInteractionInitializing,
    setIsAutoInteractionInitialized,
    setSocketConnected,
    setAutoInteractionId,
    setSocketRoom,
    setEnableCollaborationInput,
    setIsCollaborateLoading,
    setAutoInteractionMessage,
    addAutoInteractionMessage,
    setCollaborationAppId,
    setCollaborateInitializationMessage,
    setCollaborationPersona,
    setCollaborateViewMode,
    addCollaborationGroupApp,
    addCollaborationGroup,
    clearActivityInteractionMessages,
    updateAutoInteractionMessage
} = collaborateSlice.actions;

export default collaborateSlice.reducer;
