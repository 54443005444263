import { cloneDeep } from "lodash";

import { useEffect, useState } from "react";
import InfiniteCanvas from "../components/InfiniteCanvas";
import GenealogyDetailsPopup from "../components/genealogy/GenealogyDetailsPopup";
import { useCenteredTree } from "../hooks/useCenteredTree";
import { useGraphTreeFormatter } from "../hooks/useGraphTreeFormatter";
import { LordIcon } from "../components/icons/LordIcon";
import "../styles/genealogy.scss";
import { GenealogySidebarLoader } from "../components/genealogy/GenealogySidebarLoader";
import { useSelector } from "react-redux";
import { formatDate } from "../utils/date.utils";
import TreeChart from "../components/genealogy/TreeChart";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import type { PersonaState } from "../store/slices/persona.slice";
import type { PersonaModel } from "../models/persona.model";
import type { UserModel } from "../models/user.model";
import type { TreeItem } from "../types/genealogy.types";

export default function GenealogyTree() {
    const personasState: PersonaState = useSelector(
        (state: any) => state.personas
    );
    const { convertArrayToTree, allPersonas } = useGraphTreeFormatter();
    const [_translate, containerRef] = useCenteredTree();
    const [selectedDateRange, setSelectedDateRange] = useState<any>([
        new Date(),
        new Date()
    ]);
    const [searchType, setSearchType] = useState("name");
    const [showTimeline, setShowTimeline] = useState(false);
    const [showOrderDropdown, setShowOrderDropdown] = useState<boolean>(false);
    const [showSearchDropdown, setShowSearchDropdown] =
        useState<boolean>(false);
    const [selectedPersona, setSelectedPersona] = useState<TreeItem | null>(
        null
    );
    const [displayOrder, setDisplayOrder] = useState<string>("Vertical");
    const [searchResults, setSearchResults] = useState<
        Array<PersonaModel | UserModel> | string[]
    >(allPersonas);
    // const [dateSearchResults, setDateSearchResults] = useState<any>([]);
    const [formattedPersonas, setFormattedPersonas] = useState<any>([]);

    useEffect(() => {
        async function getPersonaTree() {
            /*
                The line commented out will send the modkdata into the algorithm to build the persona tree
                instead of the data from the state.
            */

            // const { personas, tree } = await convertArrayToTree(mockData);
            const { personaOrUserList: personas, tree } =
                await convertArrayToTree(cloneDeep(personasState.personas));
            setFormattedPersonas(tree);
            setSearchResults(personas);
        }
        getPersonaTree();
    }, [personasState.personas]);

    const handleSearch = (searchString: string) => {
        if (searchString.length < 3) {
            setSearchResults(allPersonas);
            return;
        }
        // const searchConditions =
        const filteredPersonas = allPersonas.filter(
            (_persona: any) => _persona?.username?.indexOf(searchString) >= 0
        );
        console.log(filteredPersonas);
        setSearchResults(filteredPersonas);
    };

    const handleToggleSearchType = (type: string) => {
        if (type === "name") {
            setSearchResults(allPersonas);
            setShowSearchDropdown(false);
            setSearchType(type);
            return;
        }
        const dates = allPersonas.map(item =>
            new Date(item.createdAt!).toLocaleDateString()
        );
        const uniqueDatesArray = dates.reduce<string[]>((acc, curr) => {
            if (!acc.includes(curr)) {
                acc.push(curr);
            }
            return acc;
        }, []);

        setSearchResults(uniqueDatesArray);
        setShowSearchDropdown(false);
        setSearchType("dates");
    };

    const handleSelectPersona = (node: TreeItem | string) => {
        if (
            !selectedPersona ||
            selectedPersona?.id !== (node as TreeItem).id ||
            selectedPersona !== node
        ) {
            setSelectedPersona(node as TreeItem);
        } else {
            setSelectedPersona(null);
        }
    };

    const testForSelected = persona => {
        if (searchType === "name") {
            return selectedPersona?.id === persona?.id;
        }
        return selectedPersona === persona;
    };

    const handleTimeline = () => {
        if (showTimeline) {
            setSelectedDateRange([new Date(), new Date()]);
            setShowTimeline(false);
            return;
        }
        setSelectedDateRange([new Date(), new Date()]);
        setShowTimeline(true);
    };

    return (
        <div className="genealogy-container">
            <div className="genealogy-container__backarrow">
                <a href="/">&#8592;</a>
            </div>
            {allPersonas?.length > 0 ? (
                <div className="genealogy-container__sidebar">
                    <div className="search-container">
                        <div className="search-container__topSection">
                            <h2>Genealogy</h2>
                            <div className="search-container__toolbar">
                                <button
                                    className=""
                                    onClick={() =>
                                        setShowOrderDropdown(!showOrderDropdown)
                                    }
                                >
                                    <span>{displayOrder}</span>
                                    <span className="dropdown-icon">
                                        {showOrderDropdown ? (
                                            // lord-icons
                                            <LordIcon
                                                src="/lord-icons/chevron-up.json"
                                                trigger="hover"
                                                colors={{ primary: "#121331" }}
                                                size={16}
                                            />
                                        ) : (
                                            <LordIcon
                                                src="/lord-icons/chevron-down.json"
                                                trigger="hover"
                                                colors={{ primary: "#121331" }}
                                                size={16}
                                            />
                                        )}
                                    </span>
                                </button>
                                <button
                                    className="timelineButton"
                                    onClick={handleTimeline}
                                >
                                    <span>timeline</span>
                                    {showTimeline ? (
                                        <span>&#60;</span>
                                    ) : (
                                        <span>&#5171;</span>
                                    )}
                                </button>
                            </div>

                            {showOrderDropdown && (
                                <div className="orderDropdown">
                                    <button
                                        onClick={() =>
                                            setDisplayOrder("Horizontal")
                                        }
                                    >
                                        Horizontal
                                    </button>
                                    <button
                                        onClick={() =>
                                            setDisplayOrder("Vertical")
                                        }
                                    >
                                        Vertical
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="search-container__bottomSection">
                            <input
                                type="text"
                                placeholder={`search ${searchType}`}
                                onChange={e => handleSearch(e.target.value)}
                            />
                            <button
                                onClick={() =>
                                    setShowSearchDropdown(!showSearchDropdown)
                                }
                                className="dropdown-container"
                            >
                                <span className="dropdown-icon">
                                    {showSearchDropdown ? (
                                        // lord-icons
                                        <LordIcon
                                            src="/lord-icons/chevron-up.json"
                                            trigger="hover"
                                            colors={{ primary: "#121331" }}
                                            size={16}
                                        />
                                    ) : (
                                        <LordIcon
                                            src="/lord-icons/chevron-down.json"
                                            trigger="hover"
                                            colors={{ primary: "#121331" }}
                                            size={16}
                                        />
                                    )}
                                </span>
                            </button>
                            {showSearchDropdown && (
                                <div className="searchDropdown">
                                    <button
                                        onClick={() =>
                                            handleToggleSearchType("name")
                                        }
                                    >
                                        Search Name
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToggleSearchType("dates")
                                        }
                                    >
                                        Search Dates
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="search-container__results">
                            {searchResults.map(
                                (
                                    personaOrUserOrDate: string | TreeItem,
                                    index
                                ) => {
                                    return (
                                        <button
                                            key={index}
                                            className={`search-container__item ${testForSelected(personaOrUserOrDate) ? "search-container__highlighted" : ""}`}
                                            onClick={() =>
                                                handleSelectPersona(
                                                    personaOrUserOrDate
                                                )
                                            }
                                        >
                                            {searchType === "name"
                                                ? (
                                                      personaOrUserOrDate as TreeItem
                                                  )?.name ||
                                                  (
                                                      personaOrUserOrDate as TreeItem
                                                  )?.username ||
                                                  (
                                                      personaOrUserOrDate as TreeItem
                                                  )?.email
                                                : formatDate(
                                                      personaOrUserOrDate as string
                                                  )}
                                        </button>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <GenealogySidebarLoader />
            )}

            <InfiniteCanvas>
                <div ref={containerRef} className="genealogy-container__main">
                    {formattedPersonas?.map(personaTree => (
                        <TreeChart
                            key={personaTree.id}
                            data={personaTree}
                            orientation={displayOrder}
                            dateRange={selectedDateRange}
                            identifierType={searchType}
                            selectedNode={selectedPersona}
                        />
                    ))}
                </div>
            </InfiniteCanvas>
            {showTimeline && (
                <div className="dateRange-container">
                    <DateRangePicker
                        onChange={setSelectedDateRange}
                        value={selectedDateRange}
                        calendarIcon={null}
                        isOpen={true}
                    />
                </div>
            )}
            {selectedPersona?.id && (
                <GenealogyDetailsPopup
                    name={
                        selectedPersona?.username || selectedPersona.email || ""
                    }
                    creator=""
                    dateOfCreation={formatDate(selectedPersona.createdAt!)}
                    dateOfDeath={selectedPersona?.dateOfDeath}
                    parents={(selectedPersona?.parent_list as string[]) ?? []}
                    handleClosePopup={() => setSelectedPersona(null)}
                />
            )}
        </div>
    );
}
