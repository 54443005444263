import "../../styles/GenealogySidebarLoader.component.scss";

export const GenealogySidebarLoader = () => {
    return (
        <div className="genealogy-container__sidebarLoader">
            <div className="genealogy-container">
                <div className="genealogy-container__topSectionLoader">
                    <a href="/">&#8592;</a>
                    <div></div>
                </div>
                <div className="genealogy-container__bottomSectionLoader">
                    <div></div>
                </div>
                <div className="genealogy-container__resultsLoader">
                    {Array.from({ length: 14 }).map((_, index) => (
                        <div
                            key={index}
                            className="genealogy-container__item"
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
};
