import React, { useRef, useEffect } from "react";
import { select, hierarchy, tree } from "d3";
import useResizeObserver from "../../hooks/useResizeObserver";
import _ from "underscore";
import "../../styles/Tree.component.scss";
import { getPersonaProfilePicture } from "../../utils/personas.utils";

interface props {
    data: any;
    orientation?: string;
    selectedNode?: any;
    dateRange?: any[];
    identifierType: string;
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function TreeChart({
    data,
    orientation = "Vertical",
    selectedNode,
    dateRange,
    identifierType
}: props) {
    const svgRef = useRef<SVGSVGElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dimensions = useResizeObserver(wrapperRef);

    // const nodeWidth = 300;
    // const nodeHeight = 75;
    // const horizontalSeparationBetweenNodes = 16;
    // const verticalSeparationBetweenNodes = 128;

    const rectW = 100;
    const rectH = 110;

    // we save data to see if it changed
    const previouslyRenderedData = usePrevious(data);
    const previouslySelectedNode = usePrevious(selectedNode);
    const previouslySelectedDateRange = usePrevious(dateRange);

    const margin = { top: 0, right: 320, bottom: 0, left: 0 };
    const width = 960 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    // Function responsible for calculating the straight paths
    function elbow(d, _i) {
        if (orientation === "Vertical") {
            return (
                "M" +
                d.source.x +
                "," +
                d.source.y +
                "V" +
                d.target.y +
                "H" +
                d.target.x +
                (d.target.children ? "" : "v" + margin.bottom)
            );
        }
        return (
            "M" +
            d.source.y +
            "," +
            d.source.x +
            "H" +
            d.target.y +
            "V" +
            d.target.x
        );
    }

    // This function will cause a re-render when either of the parameters change
    useEffect(() => {
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();
    }, [orientation, previouslySelectedNode, previouslySelectedDateRange]);

    // will be called initially and on every data change
    useEffect(() => {
        const svg = select(svgRef.current);

        // use dimensions from useResizeObserver,
        // but use getBoundingClientRect on initial render
        // (dimensions are null for the first render)

        // transform hierarchical data
        const root = hierarchy(data);
        const treeLayout = tree()
            .separation(function (a, b) {
                return a.parent === b.parent ? 2 : 1;
            })
            .size([height, width]);

        // enrich hierarchical data with coordinates
        treeLayout(root);

        let nodes: any = _.uniq(
            root.descendants(),
            _.iteratee(x => x.data.id)
        );
        let links = root.links();

        // recalculate the x poition of each of then node after the removal
        _.each(nodes, function (o, i) {
            nodes[i].id = nodes[i].data.id;
        });
        _.each(links, (link, i) => {
            links[i].target = _.find(nodes, { id: link.target.data.id });
        });

        // remove root node and all its links
        nodes = _.filter(nodes, node => !!node.parent);
        links = _.filter(links, link => !!link.source.parent);

        // nodes
        svg.selectAll(".node")
            .data(nodes)
            .join(enter =>
                enter
                    .append("rect")
                    .attr("width", rectW)
                    .attr("height", rectH)
                    .attr("rx", 8)
                    .attr("opacity", 0)
            )
            .attr("class", node => {
                const firstDate = dateRange?.[0].getTime();
                const secondDate = dateRange?.[1].getTime();
                const nodeDate = new Date(
                    node.data.createdAt as number
                ).getTime(); // this may not work with the data as that data is already in timestamp format
                const testIfNodeIsSelected = () => {
                    if (identifierType === "dates") {
                        const _nodeDate = new Date(
                            node.data.createdAt as number
                        ).toLocaleDateString();
                        return selectedNode === _nodeDate;
                    }
                    return selectedNode?.id === node?.id;
                };
                if (
                    (firstDate <= nodeDate && nodeDate <= secondDate) ||
                    testIfNodeIsSelected()
                ) {
                    return "highlightedNode";
                }
                return "node";
            })
            .attr("x", node =>
                orientation === "Vertical"
                    ? node.x - rectW / 2
                    : node.y - rectH / 2
            )
            .attr("y", node =>
                orientation === "Vertical"
                    ? node.y - rectH / 2
                    : node.x - rectW / 2
            )
            .attr("dy", ".35em")
            .attr("text-anchor", "middle")
            .attr("opacity", 1);

        // links
        const enteringAndUpdatingLinks = svg
            .selectAll(".link")
            .data(links)
            .join("path")
            .attr("class", "link")
            .attr("d", elbow)
            .lower()
            .attr("stroke", "black")
            .attr("fill", "none")
            .attr("shape-rendering", "crispEdges")
            .attr("opacity", 1);

        if (data !== previouslyRenderedData) {
            enteringAndUpdatingLinks
                .attr("stroke-dashoffset", function (this: SVGPathElement) {
                    return this.getTotalLength();
                })
                .transition()
                .duration(500)
                .delay(link => link.source.depth * 500)
                .attr("stroke-dashoffset", 0);
        }

        // labels
        svg.selectAll(".label")
            .data(nodes)
            .join(enter => {
                return enter
                    .append("text")
                    .attr("x", rectW / 2)
                    .attr("y", rectH / 2)
                    .attr("opacity", 0);
            })
            .attr("class", "label")
            .attr("x", node =>
                orientation === "Vertical" ? node.x : node.y - 5
            )
            .attr("y", node =>
                orientation === "Vertical" ? node.y + 15 : node.x + 15
            )
            .attr("text-anchor", "middle")
            .attr("font-size", 24)
            .text(
                node => node.data.name || node.data.username || node.data.email
            )
            .transition()
            .duration(500)
            .delay(node => node.depth * 300)
            .attr("opacity", 1);

        // image
        svg.selectAll(".img")
            .data(nodes)
            .join(enter => {
                return enter
                    .append("image")
                    .attr("xlink:href", node => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        return getPersonaProfilePicture(node.data);
                    })
                    .attr("x", rectW / 2)
                    .attr("y", rectH / 2)
                    .attr("opacity", 0);
            })
            .attr("class", "img")
            .attr("height", 28)
            .attr("width", 28)
            .attr("x", node =>
                orientation === "Vertical" ? node.x - 15 : node.y - 15
            )
            .attr("y", node =>
                orientation === "Vertical" ? node.y - 40 : node.x - 40
            )
            .transition()
            .duration(500)
            .delay(node => node.depth * 300)
            .attr("opacity", 1);

        // tags
        svg.selectAll("#tag")
            .data(nodes)
            .join(enter => {
                return enter
                    .append("foreignObject")
                    .attr("x", rectW / 2)
                    .attr("y", rectH / 2)
                    .attr("opacity", 0);
            })
            .attr("id", "tag")
            .attr("class", node => `tag-${node.data.type}`)
            .attr("height", 20)
            .attr("width", 60)
            .attr("x", node =>
                orientation === "Vertical" ? node.x - 30 : node.y - 30
            )
            .attr("y", node =>
                orientation === "Vertical" ? node.y + 25 : node.x + 25
            )
            .text(node => node.data.type)
            .attr("text-anchor", "middle")
            .attr("font-size", 12)
            .transition()
            .duration(500)
            .delay(node => node.depth * 300)
            .attr("opacity", 1);
    }, [
        data,
        selectedNode,
        orientation,
        dateRange,
        identifierType,
        dimensions,
        previouslyRenderedData
    ]);

    return (
        <div ref={wrapperRef}>
            <svg ref={svgRef}></svg>
        </div>
    );
}

export default TreeChart;
