import React, { useEffect, useRef, useState } from "react";
import "../styles/AudioCard.component.scss";
import Wavesurfer from "wavesurfer.js";
// import { FaPlay, FaPause } from "react-icons/fa";
import { Button, SkeletonCircle } from "@chakra-ui/react";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { LuBookmark, LuLink } from "react-icons/lu";
import { TbLayoutNavbarExpand } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { formatDate } from "../utils/date.utils";
import { BsPause, BsPlay } from "react-icons/bs";
import { formatRecordingTime } from "../utils/strings.utils";
import { useSelector } from "react-redux";

interface AudioCardProps {
    audioSrc: string;
    isInGroup: boolean;
    roomId?: string;
    messageId?: string;
    isOwnMessage: boolean;
    hideThread?: boolean;
    loading?: boolean;
    otherProps?: any;
    onRateResponse?: () => Promise<boolean>;
}

const RoomAudioMessageCard: React.FC<AudioCardProps> = ({
    audioSrc,
    hideThread,
    onRateResponse,
    isOwnMessage,
    isInGroup,
    roomId,
    messageId,
    otherProps
}) => {
    const [hasImageLoaded, setHasImageLoaded] = useState(false);
    const [isWaveformLoaded, setIsWaveformLoaded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [threadCount, _setThreadCount] = useState<number>(0);
    const [sentiment, setSentiment] = useState("");
    const [audioDuration, setAudioDuration] = useState(0);
    // const toast = useToast();
    const { user } = useSelector((state: any) => state.auth);
    const [messageAuthor, setMessageAuthor] = useState<{
        name: string;
        profile_picture?: string;
        type: string;
    }>();

    const changeSentiment = (type: string) => {
        setSentiment(type);
    };

    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<Wavesurfer | null>(null);

    useEffect(() => {
        if (waveformRef.current) {
            wavesurfer.current = Wavesurfer.create({
                container: waveformRef.current,
                waveColor: "#acacbe",
                progressColor: "#202123",
                height: 30,
                barWidth: 2
            });

            wavesurfer.current.load(audioSrc);
            wavesurfer.current.on("play", () => setIsPlaying(true));
            wavesurfer.current.on("pause", () => setIsPlaying(false));
            wavesurfer.current.on("finish", () => setIsPlaying(false));
            wavesurfer.current.on("ready", (duration: number) => {
                setIsWaveformLoaded(true);
                setAudioDuration(Math.round(duration));
            });
            wavesurfer.current.on("timeupdate", currentTime =>
                setAudioDuration(Math.round(currentTime))
            );
        }

        return () => {
            wavesurfer.current && wavesurfer.current.destroy();
        };
    }, [audioSrc]);

    useEffect(() => {
        if (isOwnMessage) {
            setMessageAuthor({
                name: user.name,
                profile_picture: user.profile_picture,
                type: "user"
            });
        } else if (otherProps.user) {
            setMessageAuthor({
                name: otherProps.user.name,
                profile_picture: otherProps.user.profile_picture,
                type: "user"
            });
        } else if (otherProps.persona) {
            setMessageAuthor({
                name:
                    otherProps.persona.first_name +
                    " " +
                    otherProps.persona.last_name,
                profile_picture: otherProps.persona.image,
                type: "persona"
            });
        }
    }, []);

    const handlePlayPause = () => {
        if (!isWaveformLoaded) {
            return;
        }

        if (wavesurfer.current) {
            if (isPlaying) {
                wavesurfer.current.pause();
            } else {
                wavesurfer.current.play();
            }
        }
    };

    /**
     * When the thumbs up button is clicked
     */
    const ratePersonaResponse = async () => {
        if (typeof onRateResponse === "function") {
            const status = await onRateResponse();
            setSentiment("1");
            // If the status from the parent is false, change it back to a button
            if (!status) {
                setSentiment("");
            }
        }
    };

    return isOwnMessage ? (
        <div className="room-card-container own-message mb-[24px]">
            <div className="trailing"></div>
            <div className="center message-box rounded-[8px] border border-gray-200 pt-[20px] px-[14px] pb-[12px]">
                <div className="grid items-center grid-cols-[40px_auto_40px]">
                    <div className="flex justify-center items-center">
                        <button
                            onClick={handlePlayPause}
                            className=" text-black font-bold h-[24px] w-[24px] rounded-full flex items-center justify-center"
                            disabled={!isWaveformLoaded}
                        >
                            {isPlaying ? (
                                <BsPause size={27} />
                            ) : (
                                <BsPlay size={26} />
                            )}
                        </button>
                    </div>

                    <div
                        ref={waveformRef}
                        className={`${!isWaveformLoaded ? " wave bg-gray-200 animate-pulse " : ""}`}
                    ></div>
                    <div className="duration text-[13px] flex justify-end">
                        {formatRecordingTime(audioDuration)}
                    </div>
                </div>
                <div className="card-footer mt-[20px]">
                    <div className="date">
                        {/* <p className="card-date text-slate-400">{capitalizeWord(getMemoryFromNamespaceString(props.data.check_list as string).sector || '')}</p> */}
                        {/* <p className="card-date text-slate-400">Montague Adameve</p> */}
                        <p className="card-date text-slate-400">
                            {formatDate("", "D MMMM YYYY")}
                        </p>
                    </div>
                    <div className="card-footer-actions">
                        {sentiment === "1" && (
                            <p className="sentiment-message gap-1.5 pr-4 text-slate-500">
                                <FiThumbsUp className="icon positive" />
                                <span>You rated "Amazing"</span>
                            </p>
                        )}
                        {sentiment === "0" && (
                            <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                <FiThumbsDown className="icon negative" />
                                <span>You rated "Could be better"</span>
                            </p>
                        )}

                        {!sentiment && (
                            <>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    onClick={ratePersonaResponse}
                                    colorScheme="blue"
                                    size="sm"
                                    // variant={props.data.Sentiment === '1' ? 'solid' : 'outline'}
                                    variant="ghost"
                                >
                                    <FiThumbsUp className="icon positive" />
                                </Button>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    onClick={() => changeSentiment("0")}
                                    colorScheme="red"
                                    size="sm"
                                    // variant={props.data.Sentiment === '0' ? 'solid' : 'outline'}
                                    variant="ghost"
                                >
                                    <FiThumbsDown className="icon negative" />
                                </Button>
                            </>
                        )}
                        <Button
                            mr={1}
                            className="icon-buttons"
                            // onClick={emitSaveResponse}
                            variant="ghost"
                        >
                            <LuBookmark className="icon save-icon" />
                        </Button>

                        <Button
                            mr={1}
                            className="icon-buttons"
                            colorScheme="gray"
                            variant="ghost"
                        >
                            <LuLink className="icon rotate" size="24px" />
                        </Button>

                        {!hideThread && (
                            <NavLink
                                to={
                                    isInGroup
                                        ? `/room-group/${roomId}/thread/${messageId}`
                                        : `/room/${roomId}/thread/${messageId}`
                                }
                            >
                                <Button
                                    title={
                                        threadCount > 0
                                            ? `Thread (${threadCount})`
                                            : "Start thread"
                                    }
                                    colorScheme={
                                        threadCount > 0 ? "brand" : "gray"
                                    }
                                    size="sm"
                                    variant={
                                        threadCount > 0 ? "solid" : "outline"
                                    }
                                >
                                    <TbLayoutNavbarExpand className="icon" />
                                    &nbsp;
                                    {threadCount > 0
                                        ? `Thread (${threadCount})`
                                        : "Start thread"}
                                </Button>
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
            <div className="leading leading-end">
                <img
                    src={user.profile_picture || "/user_avatar.png"}
                    className={`${hasImageLoaded ? "block" : "hidden"}`}
                    onLoad={() => setHasImageLoaded(true)}
                    alt=""
                />
                {!hasImageLoaded && <SkeletonCircle size="30" />}
            </div>
        </div>
    ) : (
        <div className="room-card-container mb-[24px]">
            <div className="leading">
                <img
                    src={messageAuthor?.profile_picture || "/user_avatar.png"}
                    className={`${hasImageLoaded ? "block" : "hidden"}`}
                    onLoad={() => setHasImageLoaded(true)}
                    alt=""
                />
                {!hasImageLoaded && <SkeletonCircle size="30" />}
            </div>

            <div className="center bg-white rounded-[8px] border border-gray-200 pt-[20px] px-[14px] pb-[12px]">
                <div className="grid items-center grid-cols-[40px_auto_40px]">
                    <div className="flex justify-center items-center">
                        <button
                            onClick={handlePlayPause}
                            className=" text-black font-bold h-[24px] w-[24px] rounded-full flex items-center justify-center"
                            disabled={!isWaveformLoaded}
                        >
                            {isPlaying ? (
                                <BsPause size={27} />
                            ) : (
                                <BsPlay size={26} />
                            )}
                        </button>
                    </div>

                    <div
                        ref={waveformRef}
                        className={`${!isWaveformLoaded ? " wave bg-gray-200 animate-pulse " : ""}`}
                    ></div>
                    <div className="duration text-[13px] flex justify-end">
                        {formatRecordingTime(audioDuration)}
                    </div>
                </div>
                <div className="card-footer mt-[20px]">
                    <div className="date">
                        {/* <p className="card-date text-slate-400">{capitalizeWord(getMemoryFromNamespaceString(props.data.check_list as string).sector || '')}</p> */}
                        <p className="card-date text-slate-400">
                            Montague Adameve
                        </p>
                        <p className="card-date text-slate-400">
                            {formatDate("", "D MMMM YYYY")}
                        </p>
                    </div>
                    <div className="card-footer-actions">
                        {sentiment === "1" && (
                            <p className="sentiment-message gap-1.5 pr-4 text-slate-500">
                                <FiThumbsUp className="icon positive" />
                                <span>You rated "Amazing"</span>
                            </p>
                        )}
                        {sentiment === "0" && (
                            <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                <FiThumbsDown className="icon negative" />
                                <span>You rated "Could be better"</span>
                            </p>
                        )}

                        {!sentiment && (
                            <>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    onClick={ratePersonaResponse}
                                    colorScheme="blue"
                                    size="sm"
                                    // variant={props.data.Sentiment === '1' ? 'solid' : 'outline'}
                                    variant="ghost"
                                >
                                    <FiThumbsUp className="icon positive" />
                                </Button>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    onClick={() => changeSentiment("0")}
                                    colorScheme="red"
                                    size="sm"
                                    // variant={props.data.Sentiment === '0' ? 'solid' : 'outline'}
                                    variant="ghost"
                                >
                                    <FiThumbsDown className="icon negative" />
                                </Button>
                            </>
                        )}
                        <Button
                            mr={1}
                            className="icon-buttons"
                            // onClick={emitSaveResponse}
                            variant="ghost"
                        >
                            <LuBookmark className="icon save-icon" />
                        </Button>

                        <Button
                            mr={1}
                            className="icon-buttons"
                            colorScheme="gray"
                            variant="ghost"
                        >
                            <LuLink className="icon rotate" size="24px" />
                        </Button>

                        {!hideThread && (
                            <NavLink
                                to={
                                    isInGroup
                                        ? `/room-group/${roomId}/thread/${messageId}`
                                        : `/room/${roomId}/thread/${messageId}`
                                }
                            >
                                <Button
                                    title={
                                        threadCount > 0
                                            ? `Thread (${threadCount})`
                                            : "Start thread"
                                    }
                                    colorScheme={
                                        threadCount > 0 ? "brand" : "gray"
                                    }
                                    size="sm"
                                    variant={
                                        threadCount > 0 ? "solid" : "outline"
                                    }
                                >
                                    <TbLayoutNavbarExpand className="icon" />
                                    &nbsp;
                                    {threadCount > 0
                                        ? `Thread (${threadCount})`
                                        : "Start thread"}
                                </Button>
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomAudioMessageCard;
