/*
======= FORMAT COLLABORATE FUNCTIONS =========
 */

import KeycloakClient from "../../hooks/keycloak.hook";
import { type FileObject } from "../../models/room.model";
// import { getAuthUser } from "../auth.utils";

export function formatCollaborateMessage(message: string | string[]) {
    if (Array.isArray(message)) {
        return message[0].split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    } else {
        return message.split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    }
}

export function getFileLink(fileObject?: FileObject) {
    if (!fileObject) {
        return "";
    }

    switch (fileObject.origin) {
        case "tool_system":
            return getCollaborationAppFileLink(fileObject);
        default:
            return fileObject.link || "";
    }
}

// export function formatCollaborationAppFileLink(fileObject?: FileObject) {
//     if (!fileObject) {
//         return <></>
//     }

//     return (
//         <a target="_blank" href={getCollaborationAppFileLink(fileObject)} download={fileObject.name} rel="noreferrer">
//             {fileObject.name}
//             <br />
//         </a>
//     );
// }

function getCollaborationAppFileLink(fileObject: FileObject) {
    const extension = fileObject.extension;
    const filename = fileObject.name_without_extension;
    const token = KeycloakClient.getInstance().token;

    return (
        process.env.REACT_APP_API_URL +
        "/api/v1/tool/download-output-file/" +
        filename +
        "/" +
        extension +
        "?access_token=" +
        token
    );
}
