import io from "socket.io-client";

const URL = process.env.REACT_APP_SOCKET_API_URL!;

export const initSocket = () =>
    io(URL, {
        autoConnect: false,
        reconnection: true,
        transports: ["websocket"]
    });
