import { ResponsiveLine } from "@nivo/line";
import "../styles/UsageChart.component.scss";

export interface LineData {
    id: string;
    color: string;
    data: Array<{ x: string; y: number }>;
}

interface UsageChartProps {
    xAxisLabel: string;
    lineData: LineData[];
}

export default function UsageChart(props: UsageChartProps) {
    return (
        <div className="chart-container">
            <div className="chart" style={{ height: "40vh", width: "100%" }}>
                <ResponsiveLine
                    data={props.lineData}
                    margin={{ top: 50, right: 30, bottom: 60, left: 60 }}
                    xScale={{ type: "point" }}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false
                    }}
                    curve="cardinal"
                    enableArea={true}
                    areaBaselineValue={60}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.xAxisLabel,
                        legendOffset: -40,
                        legendPosition: "middle"
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 15
                    }}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                />
            </div>
        </div>
    );
}
