import {
    type IRoomParticipant,
    type RoomGroupModel
} from "../../models/room.model";
import { sortListByKey } from "../array.utils";

export function getRoomParticipantsUtil(
    room: RoomGroupModel
): IRoomParticipant[] {
    let personaParticipants: IRoomParticipant[] = [];
    let userParticipants: IRoomParticipant[] = [];

    if (room.persona_member_list) {
        personaParticipants = room.persona_member_list
            .filter(personaMember => !!personaMember?.persona?.id)
            .map(personaMember => ({
                id: personaMember.persona.id!,
                member_id: personaMember.id,
                name:
                    personaMember.persona?.first_name +
                    " " +
                    personaMember.persona?.last_name,
                profile_picture: personaMember.persona?.profile_picture,
                type: "persona",
                persona_type: personaMember.persona?.type
            }));
    }

    if (room.user_member_list) {
        userParticipants = room.user_member_list
            .filter(userMember => !!userMember?.user?.id)
            .map(userMember => ({
                id: userMember.user?.id,
                member_id: userMember.id,
                name: userMember?.user?.name || userMember?.user?.email,
                profile_picture: userMember?.user?.profile_picture,
                type: "user"
            }));
    }

    return sortListByKey<IRoomParticipant>(
        [...(personaParticipants || []), ...userParticipants],
        "name"
    );
}
