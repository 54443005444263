import { useEffect, useState, useRef } from "react";
import { type Socket } from "socket.io-client";

import BrainActivity from "../components/waiting-room/BrainActivity";
import "../styles/WaitingRoom.component.scss";
import ProfileDropdown from "../components/ProfileDropdown";
import RoomMessageCard from "../components/RoomMessageCard";
import RoomMessageInput from "../components/RoomMessageInput";
import { NavLink, useParams } from "react-router-dom";
import { ViewLayoutComponent } from "../components/ViewLayout.component";
import { useHttpClient } from "../utils/http.utils";
import { LordIcon } from "../components/icons/LordIcon";
import { CiCircleInfo } from "react-icons/ci";
import { TemporaryRecords } from "../components/TemporaryRecords";
import RoomAudioMessageCard from "../components/RoomAudioMessageCard";
import RoomVideoMessageCard from "../components/RoomVideoMessageCard";
import {
    type FileObject,
    type IRoomParticipant,
    type RoomGroupModel,
    type RoomMessage
} from "../models/room.model";
import { useDispatch, useSelector } from "react-redux";
// import { GroupsState } from "../store/slices/groups.slice";
import {
    type RoomsState,
    selectActiveRoomId,
    setActiveRoomFileList,
    setActiveRoomId,
    setWatchingCollabIdList
} from "../store/slices/rooms.slice";
import {
    type RoomMessageWithLoading,
    addMessage,
    clearMessages,
    confirmMessage,
    setMessages,
    updateMessageLoading
} from "../store/slices/message.slice";
import generateUUID from "../utils/collaborate/generateUUID";
import { sortListByKey } from "../utils/array.utils";
import { setBrainScanPersonas } from "../store/slices/brain-scan.slice";
import { initSocket } from "../utils/socket.utils";
import toast from "react-hot-toast";
import { getFileLink } from "../utils/collaborate/parser";
import { type AuthState } from "../store/slices/auth.slice";

const RoomView = () => {
    const dispatch = useDispatch();
    const messages: RoomMessageWithLoading[] = useSelector(
        (state: any) => state.messages
    );
    const { id } = useParams();
    // First check if room type is a group
    // const isGroup = window.location.pathname.includes("/room-group/");
    const activeRoomId = useSelector(selectActiveRoomId);

    const roomsState: RoomsState = useSelector((state: any) => state.rooms);
    // const groupsState: GroupsState = useSelector((state: any) => state.groups);
    const [roomData, setRoomData] = useState<RoomGroupModel>();
    const socketRef = useRef<Socket | null>(null);
    const { data: loggedInUser } = useSelector((state: any) => state.account);
    const localIDsSent = useRef<Set<string>>(new Set());
    const [roomParticipants, setRoomParticipants] = useState<
        IRoomParticipant[]
    >([]);
    const authUser: AuthState = useSelector((state: any) => state.auth);

    const messagesRef = useRef(messages);

    messagesRef.current = messages;
    // const navigate = useNavigate();
    const messagesContainerRef = useRef<HTMLDivElement>(null);

    const {
        listRoomMessageApi,
        createRoomMessageApi,
        addVideoToRoomMessageApi,
        addAudioToRoomMessageApi,
        addMessageToHistoryApi,
        listFileApi,
        listAdditionalRoomsApi
    } = useHttpClient();

    useEffect(() => {
        if (authUser.user?.email) {
            (async () =>
                await listAdditionalRoomsApi({
                    search_criteria: {
                        id
                    }
                }))();
        }
    }, [authUser.user]);

    useEffect(() => {
        const fetchFileList = async () => {
            const fileList = await listFileApi({ room: activeRoomId });

            dispatch(setActiveRoomFileList(fileList));
        };

        if (activeRoomId) {
            fetchFileList();
        }
    }, [dispatch, activeRoomId]);

    useEffect(() => {
        // Clear messages
        dispatch(clearMessages());
        // Get room data from state
        // Then get the room data

        // if (groupsState.data && roomsState.data) {
        if (roomsState.data) {
            const room = roomsState.data.find(data => data.id === id);
            let personaParticipants: IRoomParticipant[] = [];
            let userParticipants: IRoomParticipant[] = [];

            if (room) {
                // Set the active room
                dispatch(setActiveRoomId(room.id));

                if (room.persona_member_list) {
                    personaParticipants = room.persona_member_list.map(
                        personaMember => ({
                            id: personaMember?.persona?.id || "",
                            member_id: personaMember?.id,
                            name:
                                personaMember?.persona?.first_name +
                                " " +
                                personaMember?.persona?.last_name,
                            profile_picture:
                                personaMember?.persona?.profile_picture,
                            type: "persona",
                            persona_type: personaMember.persona?.type
                        })
                    );
                    dispatch(
                        setBrainScanPersonas(
                            room.persona_member_list.map(
                                personaMember => personaMember.persona
                            )
                        )
                    );
                }

                if (room.user_member_list) {
                    userParticipants = room.user_member_list.map(
                        userMember => ({
                            id: userMember?.user?.id,
                            member_id: userMember?.id,
                            name:
                                userMember?.user?.name ||
                                userMember?.user?.email,
                            profile_picture: userMember?.user?.profile_picture,
                            type: "user"
                        })
                    );
                }

                setRoomParticipants(
                    sortListByKey<IRoomParticipant>(
                        [...(personaParticipants || []), ...userParticipants],
                        "name"
                    )
                );

                // set data
                setRoomData(room);
                // Fetch messages from server
                const fetchMessages = async () => {
                    // Do not include threaded messages
                    const toastId = toast.loading("Loading messages...");

                    const fetchedMessages = await listRoomMessageApi({
                        room: id!,
                        or: [{ parent_id: null }, { parent_id: "" }]
                    });

                    const collabMessages: RoomMessage[] =
                        await listRoomMessageApi({
                            room: id!,
                            collaboration_app: { "!=": null }
                        });

                    const messagesWithLoading: RoomMessageWithLoading[] =
                        fetchedMessages.map((message: RoomMessage) => ({
                            message,
                            loading: false,
                            localID: message.id!
                        }));

                    dispatch(setMessages(messagesWithLoading));
                    dispatch(
                        setWatchingCollabIdList(
                            collabMessages.map(
                                message => message.collaboration_app!
                            )
                        )
                    );

                    toast.dismiss(toastId);
                };

                fetchMessages();
            }
        }
    }, [roomsState.data]);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = initSocket();
        }

        socketRef.current.connect();

        if (socketRef.current) {
            socketRef.current.on(`room-message-creation-${id}`, data => {
                // change nature of user id so it can be matched and showed on the right side
                const incomingMessage = { ...data.room_message };

                // Check if the message ID is in the messages you have sent
                const existingMessageByID = messagesRef.current.find(
                    msg => msg.message.id === incomingMessage.id
                );

                // If it exists, it's an acknowledgment from the server
                if (existingMessageByID) {
                    dispatch(
                        updateMessageLoading({
                            id: incomingMessage.id,
                            loading: false
                        })
                    );

                    if (localIDsSent.current.has(existingMessageByID.localID)) {
                        dispatch(
                            confirmMessage({
                                localID: existingMessageByID.localID,
                                confirmedID: incomingMessage.id
                            })
                        );
                        localIDsSent.current.delete(
                            existingMessageByID.localID
                        ); // Remove the localID since it's now confirmed by the server
                    }
                } else {
                    const newMessage = {
                        message: {
                            ...incomingMessage,
                            user: {
                                id: data.room_message.user
                            }
                        },
                        loading: false,
                        localID: generateUUID() // Generate a new localID for this incoming message
                    };
                    dispatch(addMessage(newMessage));
                }
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.removeAllListeners();
                socketRef.current.disconnect();
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (messagesContainerRef.current) {
            const { scrollHeight, clientHeight } = messagesContainerRef.current;
            const scrollPosition = scrollHeight - clientHeight;
            messagesContainerRef.current.scrollTo(0, scrollPosition);
        }
    }, [messages]);

    const isGroup = () => roomData?.is_single !== "yes";

    const onVoiceRecorded = async (blob: Blob) => {
        try {
            const response = await addAudioToRoomMessageApi(blob);

            if (response) {
                await createRoomMessageApi({
                    room: id,
                    user: loggedInUser?.id,
                    message_type: "voice",
                    message_data: `${process.env.REACT_APP_API_URL}/api/v1/room-message/show-audio-media/${response?.data?.saved_filename}/${response?.data?.extension}`,
                    tagged_member_list: []
                });
            }
        } catch (error) {
            console.error("Failed to upload audio:", error);
        }
    };

    const onVideoRecorded = async (blob: Blob) => {
        try {
            const localID = generateUUID();
            localIDsSent.current.add(localID);

            const toastId = toast.loading("Uploading video, Please wait..", {
                duration: 9000000000000
            });
            // upload video to server,
            const response = await addVideoToRoomMessageApi(
                blob,
                "recorded-video.webm"
            );
            // upload to server
            if (response) {
                // Create a new message object
                const messageToSend: RoomMessageWithLoading = {
                    message: {
                        room: id,
                        user: loggedInUser?.id,
                        message_type: "video",
                        message_data: response?.data.video_id,
                        tagged_member_list: [],
                        // tagged_user_list: [],
                        type: "room_message",
                        createdAt: Date.now()
                    },
                    loading: true,
                    localID
                };
                await createRoomMessageApi(messageToSend.message);
            }

            // remove loading
            toast.dismiss(toastId);
        } catch (error) {
            console.error("Failed to upload video:", error);
        }
    };

    const onText = async ({ message, taggedMemberList }) => {
        const localID = generateUUID();
        localIDsSent.current.add(localID);
        // Create a new message object
        const messageToSend: RoomMessageWithLoading = {
            message: {
                room: id,
                user: loggedInUser?.id,
                message_type: "text",
                message_data: message,
                tagged_member_list:
                    roomData?.is_single === "yes"
                        ? [roomData.persona_member_list[0].id]
                        : taggedMemberList,
                record_url: "",
                record_file: "",
                type: "room_message",
                createdAt: Date.now()
            },
            loading: true,
            localID
        };
        // dispatch(addMessage(messageToSend));
        try {
            const response = await createRoomMessageApi(messageToSend.message);

            const confirmedID = response.room_message.id;

            if (confirmedID) {
                dispatch(
                    confirmMessage({
                        localID,
                        confirmedID
                    })
                );
            } else {
                console.error(
                    "Error: No confirmed ID received from the server."
                );
            }
        } catch (error) {
            console.error("Error sending the text message:", error);
        }
    };

    const ratePersonaResponse = async (message: RoomMessage) => {
        const data = await addMessageToHistoryApi({
            message: message.message_data,
            messageId: message.id,
            userId: loggedInUser.id,
            personaId: loggedInUser.id // TODO: CHANGE TO PERSONA ID, THIS IS DONE FOR TESTING PURPOSES
        });

        if (data === false) {
            // Send a status to the message component that the message has been rated
            return false;
        } else {
            // Send a status to the message component that the message has not been rated
            return true;
        }
    };

    return (
        <ViewLayoutComponent>
            <div className="waiting-room">
                <div className="grid-column bg-white pt-[12px]">
                    <ProfileDropdown dropDownStyle={{ boxShadow: "none" }} />
                    {/* <WaitingLeftPane /> */}
                    <div className="pt-[20px] temporary-record-holder">
                        <TemporaryRecords />
                    </div>
                </div>
                <div className="main-grid grid-column">
                    <div className="main-grid-wrapper">
                        <div className="waiting-room-header-box">
                            <div className="header-box">
                                <h2 className="title">
                                    {roomData?.title}
                                    <p className="title-description truncate">
                                        {roomData?.description}
                                    </p>
                                </h2>
                                <div className="subtitle flex w-full overflow-hidden truncate">
                                    {/* <p className="name">{roomData?.owner.email}</p> */}

                                    {roomParticipants
                                        .slice(0, 3)
                                        .map((participant, index) => {
                                            return (
                                                <span
                                                    key={"participant_" + index}
                                                    className="name whitespace-nowrap"
                                                >
                                                    {index > 0 && ","}&nbsp;
                                                    {participant.name}{" "}
                                                    {participant.type !==
                                                        "user" && (
                                                        <span className="arin-text">
                                                            {
                                                                participant.persona_type
                                                            }
                                                        </span>
                                                    )}
                                                </span>
                                            );
                                        })}
                                    {roomParticipants.length > 4 && (
                                        <span>
                                            & ${roomParticipants.length - 4}{" "}
                                            others
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="line w-full">
                                <div className="line-inner"></div>
                            </div>
                            <div className="actions-box">
                                <NavLink to={"/"}>
                                    <button className="button">
                                        <LordIcon
                                            src="/lord-icons/stack.json"
                                            trigger="hover"
                                            colors={{
                                                primary: "#121331",
                                                secondary: "#333"
                                            }}
                                            stroke={40}
                                            size={30}
                                        />
                                    </button>
                                </NavLink>

                                <button className="button">
                                    <CiCircleInfo
                                        className="text-gray-600"
                                        size="22px"
                                    />
                                </button>
                            </div>
                        </div>
                        {/* <div className="waiting-room-header-content lg-view">
                        <NavLink to={'/'}>
                            <button className="back-button">
                                <LordIcon
                                    src="/lord-icons/stack.json"
                                    trigger="hover"
                                    colors={{
                                        primary: '#121331',
                                        secondary: '#333',
                                    }}
                                    stroke={40}
                                    size={30}
                                />
                            </button>
                        </NavLink>
                        <div className="divider"></div>
                        <p className="">
                            with montague adameve (arin), paul snow, james
                            duchenne & 235 others.
                        </p>
                    </div> */}
                        <div
                            className="waiting-room-body-content"
                            ref={messagesContainerRef}
                        >
                            {/* <RoomMessageCard
                                mode="info"
                                text={[
                                    "Welcome to Neo Al's VIP room. Stay up to date with Al while we roll out Neo. #FollowTheWhiteRabbit",
                                ]}
                            /> */}

                            {messages.map((_, index, arr) => {
                                const item = arr[arr.length - 1 - index];
                                const message = item.message;

                                const isOwnMessage =
                                    message?.user?.id === loggedInUser?.id;

                                if (message.message_type === "text") {
                                    return (
                                        <div key={message.id}>
                                            {message.message_data && (
                                                <RoomMessageCard
                                                    messageId={message.id}
                                                    questionId={
                                                        message.question_id
                                                    }
                                                    roomId={id}
                                                    text={[
                                                        message.message_data
                                                    ]}
                                                    isInGroup={isGroup()}
                                                    isOwnMessage={isOwnMessage}
                                                    fileObject={
                                                        (
                                                            message.file_object as FileObject
                                                        )?.id
                                                            ? {
                                                                  name: (
                                                                      message.file_object as FileObject
                                                                  ).name,
                                                                  link: getFileLink(
                                                                      message.file_object as FileObject
                                                                  )
                                                              }
                                                            : undefined
                                                    }
                                                    isRated={message.isRated}
                                                    loading={item.loading}
                                                    otherProps={message}
                                                    auto_interaction_message_type={
                                                        message.auto_interaction_message_type
                                                    }
                                                    onRateResponse={async () =>
                                                        await ratePersonaResponse(
                                                            message
                                                        )
                                                    }
                                                    date={message.createdAt}
                                                />
                                            )}
                                        </div>
                                    );
                                } else if (message.message_type === "video") {
                                    return (
                                        <div key={message.id}>
                                            <RoomVideoMessageCard
                                                videoId={Number(
                                                    message.message_data
                                                )}
                                                isOwnMessage={isOwnMessage}
                                                loading={item.loading}
                                                messageId={message.id}
                                                roomId={id}
                                                isInGroup={isGroup()}
                                                otherProps={message}
                                                onRateResponse={async () =>
                                                    await ratePersonaResponse(
                                                        message
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                } else if (message.message_type === "voice") {
                                    return (
                                        <div key={message.id}>
                                            <RoomAudioMessageCard
                                                isOwnMessage={isOwnMessage}
                                                audioSrc={message.message_data!}
                                                loading={item.loading}
                                                messageId={message.id}
                                                roomId={id}
                                                isInGroup={isGroup()}
                                                otherProps={message}
                                                onRateResponse={async () =>
                                                    await ratePersonaResponse(
                                                        message
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={message.id}>
                                            <></>
                                        </div>
                                    );
                                }
                            })}

                            <RoomMessageInput
                                room={roomData}
                                disableTagging={roomData?.is_single === "yes"}
                                isGroup={isGroup()}
                                showActions={["write", "voice", "video"]}
                                onVoice={onVoiceRecorded}
                                onVideo={onVideoRecorded}
                                onText={onText}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid-column bg-white activity-grid pt-[12px]">
                    <BrainActivity />
                </div>
            </div>
        </ViewLayoutComponent>
    );
};

export default RoomView;
