import React, { useState } from "react";
import "../styles/SearchInput.component.scss";
import { BsSearch } from "react-icons/bs";
import debounce from "lodash.debounce";

interface SearchInputProps {
    onChange: (value: string) => void;
}
function SearchInput(props: SearchInputProps) {
    const [isFocused, setIsFocused] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string | undefined>();

    // Create a debounced version of the search function
    const debouncedSearch = debounce((term: string) => {
        props.onChange(term);
    }, 600); // Adjust the debounce delay as needed (e.g., 300 milliseconds)

    // Handle input change and trigger the debounced search
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);
        debouncedSearch(term);
    };

    return (
        <div className={`input-container px-2 ${isFocused ? "focused" : ""}`}>
            <input
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleInputChange}
            />
            <BsSearch
                style={{ color: "var(--gray-500)" }}
                size={isFocused ? 14 : 18}
            />
        </div>
    );
}

export default SearchInput;
