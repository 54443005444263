import { AiOutlineDelete } from "react-icons/ai";
import "../styles/RoomMessageInput.component.scss";
import { CiMail } from "react-icons/ci";
import { useEffect, useRef, useState } from "react";
import { HiOutlinePencil, HiOutlineMicrophone } from "react-icons/hi";
import toast from "react-hot-toast";
import { useHttpClient } from "../utils/http.utils";
import { LordIcon } from "./icons/LordIcon";
import { FiVideo } from "react-icons/fi";
import { IoIosAttach } from "react-icons/io";
import { PiSmileyLight } from "react-icons/pi";
import { IoSendSharp } from "react-icons/io5";
import { Button } from "@chakra-ui/react";
import { BsMic } from "react-icons/bs";
import { formatRecordingTime } from "../utils/strings.utils";
import { useReactMediaRecorder } from "react-media-recorder-2";
import {
    SupportedAudioMimeTypes,
    SupportedVideoMimeTypes
} from "../utils/media.utils";
import { type RoomGroupModel } from "../models/room.model";
import { sortListByKey } from "../utils/array.utils";
import EmojiPicker from "emoji-picker-react";

type ActionType =
    | "all"
    | "speak"
    | "write"
    | "voice"
    | "text"
    | "video"
    | "subscribe";

declare module "react" {
    interface HTMLAttributes<T> extends DOMAttributes<T> {
        placeholder?: string;
    }
}

interface IRoomMessageText {
    message: string;
    taggedMemberList: string[];
}
interface IRoomMessageInputProps {
    showActions: ActionType[];
    isGroup?: boolean;
    disableTagging: boolean;
    room?: RoomGroupModel;
    isTextDisabled?: boolean;
    onVoice?: (blob: Blob) => void;
    onVideo?: (blob: Blob) => void;
    onText?: (data: IRoomMessageText) => void;
}

interface IRoomParticipant {
    id: string;
    name?: string;
    profile_picture?: string;
    type?: string;
}

const RoomMessageInput = (props: IRoomMessageInputProps) => {
    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
    const [panelAction, setPanelAction] = useState("");
    const [audioRecorderPlayTime, setAudioRecorderPlayTime] = useState(0);
    const { subscribeToRoomApi } = useHttpClient();
    // const audioRecorder = useAudioRecorder();
    const videoRecorder = useReactMediaRecorder({
        video: true,
        audio: false,
        mediaRecorderOptions: {
            mimeType: SupportedVideoMimeTypes[0]
        }
    });
    const soundRecorder = useReactMediaRecorder({
        video: false,
        audio: true,
        mediaRecorderOptions: { mimeType: SupportedAudioMimeTypes[0] }
    });
    const videoRecorderPreviewRef = useRef<HTMLVideoElement>(null);
    const messageInputRef = useRef<HTMLDivElement>(null);

    const [isVideoInitialized, setIsVideoInitialized] = useState(false);
    const [roomParticipants, setRoomParticipants] = useState<
        IRoomParticipant[]
    >([]);
    const [searchedParticipants, setSearchedParticipants] = useState<
        IRoomParticipant[]
    >([]);
    const [taggedMembers, setTaggedMembers] = useState<string[]>([]);
    // const [taggedUsers, setTaggedUsers] = useState<string[]>([]);

    const videoChunks: MediaStream[] = [];
    const audioChunks: MediaStream[] = [];
    const stopTimeSimulationHandler = useRef<(() => void) | null>(null);
    const tagMenuContainerRef = useRef<HTMLDivElement>(null);
    const emojiPickerRef = useRef<HTMLDivElement>(null);

    // when video stream changes, update video ref
    useEffect(() => {
        if (
            videoRecorder.previewStream &&
            videoRecorder.status === "recording"
        ) {
            videoRecorderPreviewRef.current!.srcObject =
                videoRecorder.previewStream;
            // Append to chunks
            videoChunks.push(videoRecorder.previewStream);
        }
    }, [videoRecorder.previewStream]);

    useEffect(() => {
        // Get room data from state
        // Then get the room data
        const room: RoomGroupModel = props.room!;
        let personaParticipants: IRoomParticipant[] = [];
        let userParticipants: IRoomParticipant[] = [];

        if (room) {
            if (room.persona_member_list) {
                personaParticipants = room.persona_member_list.map(
                    personaMember => ({
                        id: personaMember.persona.id!,
                        member_id: personaMember.id,
                        name:
                            personaMember.persona?.first_name +
                            " " +
                            personaMember.persona?.last_name,
                        profile_picture: personaMember.persona?.profile_picture,
                        type: "persona"
                    })
                );
            }

            if (room.user_member_list) {
                userParticipants = room.user_member_list.map(userMember => ({
                    id: userMember.user?.id,
                    member_id: userMember.id,
                    name: userMember?.user?.name,
                    profile_picture: userMember?.user?.profile_picture,
                    type: "user"
                }));
            }

            setRoomParticipants(
                sortListByKey<IRoomParticipant>(
                    [...personaParticipants, ...userParticipants],
                    "name"
                )
            );
        }
    }, [props]);

    function audioPlayBackCalculator(playInterval: number, onPlaytimeUpdate) {
        let playtime = 0;

        const playTimer = setInterval(() => {
            playtime += 1;
            if (onPlaytimeUpdate) {
                onPlaytimeUpdate(playtime);
            }
        }, playInterval);

        // Return a function to stop the simulation
        return function stopTimeSimulationHandler() {
            clearInterval(playTimer);
        };
    }

    // audio stream
    useEffect(() => {
        if (
            soundRecorder.previewStream &&
            soundRecorder.status === "recording"
        ) {
            // Append to chunks
            audioChunks.push(soundRecorder.previewStream);
        }
        // listen to audio stream end
        // soundRecorder.onStop = (blobUrl, blob) => {
        //     console.log(blob);
        //     console.log(blobUrl);
        // });
    }, [soundRecorder.previewStream]);

    const showAction = (action: ActionType) => {
        return (
            props.showActions.includes("all") ||
            props.showActions.includes(action)
        );
    };

    const changePanelMenu = (e: any, action: string) => {
        setPanelAction(action);
        // remove active class from all actions buttons
        document
            .querySelectorAll(".actions-container .action-button")
            .forEach(el => {
                el.classList.remove("active");
            });
        // set the current to active
        (e.target as HTMLElement).classList.add("active");
        // hide controls
        // if (audioRecorder.isRecording) {
        //     audioRecorder.stopRecording();
        // }
        // remove video if record
        if (videoRecorder.status === "recording") {
            videoRecorder.stopRecording();
        }
        // remove sound if record
        if (soundRecorder.status === "recording") {
            soundRecorder.stopRecording();
        }
        // actions
        switch (action) {
            case "subscribe":
                subscribeToRoom();
                break;
            case "voice":
                stopTimeSimulationHandler.current = audioPlayBackCalculator(
                    1000,
                    (playtime: number) => {
                        setAudioRecorderPlayTime(playtime);
                    }
                );
                // audioRecorder.startRecording();
                soundRecorder.startRecording();
                break;
            case "video":
                videoRecorder.startRecording();
                break;
        }
    };

    const subscribeToRoom = () => {
        if (props.room?.id) {
            toast.promise(subscribeToRoomApi(props.room.id), {
                loading: "Subscribing to room",
                success: "Successfully subscribed to room",
                error: "Failed to subscribe to room"
            });
        }
    };

    // const onAudioRecorded = (blob: Blob) => {
    //     console.log(blob);
    // };

    const cancelAudioRecording = () => {
        setPanelAction("");
        soundRecorder.stopRecording();
    };

    const cancelVideoRecording = () => {
        setPanelAction("");
        videoRecorder.stopRecording();
        soundRecorder.startRecording();
    };

    const clearTextInput = () => {
        setIsActionMenuOpen(false);
        setPanelAction("");
    };

    const completeAudioRecording = () => {
        setPanelAction("");
        soundRecorder.stopRecording();
        videoRecorder.stopRecording();

        // clear simulation
        if (typeof stopTimeSimulationHandler.current === "function") {
            stopTimeSimulationHandler.current();
            stopTimeSimulationHandler.current = null;
        }

        if (soundRecorder.mediaBlobUrl) {
            fetch(soundRecorder.mediaBlobUrl)
                .then(async res => await res.blob())
                .then(blob => {
                    // emit data
                    if (typeof props.onVoice === "function") {
                        props.onVoice(blob);
                    }
                    // clear input
                    clearTextInput();
                });
        }
    };

    const handleTextSubmit = () => {
        const message = messageInputRef.current?.innerText;
        if (message && typeof props.onText === "function") {
            props.onText({
                message,
                taggedMemberList: taggedMembers
            });

            messageInputRef.current.innerText = "";
            setTaggedMembers([]);
        }
        // clear input
        clearTextInput();
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        event.preventDefault();

        const escapeKeys = [
            "ArrowLeft",
            "ArrowRight",
            "ArrowUp",
            "ArrowDown",
            "Backspace",
            "Meta"
        ];

        if (escapeKeys.includes(event.key)) {
            return;
        }

        const input = messageInputRef.current!;
        const words = input.innerText.split(" ");
        const tagMenuContainer = tagMenuContainerRef.current!;
        const hasTag = words[words.length - 1].startsWith("@");
        let range;
        let selection;

        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleTextSubmit();
        }
        // show menu when @ is pressed
        // if the last word contains @

        if (hasTag && !props.disableTagging) {
            // Search for participants
            const searchText = words[words.length - 1].slice(1);

            if (!searchText) {
                setSearchedParticipants(roomParticipants);
                tagMenuContainer.style.display = "block";
            } else {
                const searchedUsers = roomParticipants.filter(participant =>
                    participant.name
                        ?.toLowerCase()
                        .includes(searchText.toLowerCase())
                );
                if (searchedUsers.length > 0) {
                    setSearchedParticipants(searchedUsers);
                    tagMenuContainer.style.display = "block";
                } else {
                    tagMenuContainer.style.display = "none";
                }
            }
        } else {
            tagMenuContainer.style.display = "none";
        }

        // update input
        // input.innerHTML = words
        //     .map((word) => {
        //         if (word.startsWith("@")) {
        //             return `<span class="text-blue-500 bg-blue-50 p-1 rounded">${word}</span>`;
        //         } else {
        //             return `<span>${word}</span>`;
        //         }
        //     })
        //     .join(" ");
        // format words and hightlight tags
        if (document.createRange) {
            range = document.createRange();
            range.selectNodeContents(input);
            range.collapse(false);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        } else if ((document as any).selection) {
            range = (document.body as any).createTextRange();
            range.moveToElementText(input);
            range.collapse(false);
            range.select();
        }
    };

    const onTagPersonaSelect = participant => {
        const input = messageInputRef.current!;
        const words = input.innerText.split(" ");
        const tagMenuContainer = tagMenuContainerRef.current!;
        let range;
        let selection;

        // remove @
        words[words.length - 1] = `@${participant.name}`;
        // Complete participants
        setTaggedMembers([...taggedMembers, participant.member_id]);
        // update input
        input.innerHTML = words
            .map(word => {
                if (word.startsWith("@")) {
                    return `<span class="text-blue-500 bg-blue-50 p-1 rounded">${word}</span> <span>&nbsp;</span>`;
                } else {
                    return `<span>${word}</span>`;
                }
            })
            .join(" ");

        // input.innerHTML = words.join(" ");
        // hide menu
        tagMenuContainer.style.display = "none";

        if (document.createRange) {
            range = document.createRange();
            range.selectNodeContents(input);
            range.collapse(false);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        } else if ((document as any).selection) {
            range = (document.body as any).createTextRange();
            range.moveToElementText(input);
            range.collapse(false);
            range.select();
        }
    };

    const completeVideoRecording = () => {
        setPanelAction("");
        videoRecorder.stopRecording();
        if (videoRecorder.mediaBlobUrl) {
            fetch(videoRecorder.mediaBlobUrl)
                .then(async res => await res.blob())
                .then(handleBlob);
            // clear input
            clearTextInput();
        }
    };

    const handleBlob = (blob: Blob) => {
        if (typeof props.onVideo === "function") {
            props.onVideo(blob);
        }
    };

    const toggleEmojiPicker = () => {
        const emojiPicker = emojiPickerRef.current!;
        emojiPicker.style.display =
            emojiPicker.style.display === "block" ? "none" : "block";
    };

    const onEmojiSelect = emojiData => {
        const input = messageInputRef.current!;
        input.innerHTML += emojiData.emoji;
    };

    const toggleActionPanel = () => {
        // hide tag menu
        if (tagMenuContainerRef.current) {
            tagMenuContainerRef.current.style.display = "none";
        }
        // show or hide action panel
        setIsActionMenuOpen(!isActionMenuOpen);
        setPanelAction("");
    };

    return (
        <div className="room-card-plus-container">
            <div className="controls-container">
                <div
                    className={`action-menu ${
                        isActionMenuOpen ? "is-menu-open" : ""
                    }`}
                >
                    <div className="actions-container">
                        {/* {showAction('speak') && (
                            <button
                                onClick={(e) => changePanelMenu(e, 'speak')}
                                className="action-button"
                            >
                                <HiOutlineSpeakerphone className="icon" />
                                Speak
                            </button>
                        )} */}

                        {showAction("write") && (
                            <button
                                onClick={e => changePanelMenu(e, "write")}
                                className="action-button"
                            >
                                <HiOutlinePencil className="icon" />
                                Write
                            </button>
                        )}
                        {showAction("voice") && (
                            <button
                                onClick={e => changePanelMenu(e, "voice")}
                                className="action-button"
                            >
                                <HiOutlineMicrophone className="icon" />
                                Voice
                            </button>
                        )}
                        {showAction("video") && (
                            <button
                                onClick={e => changePanelMenu(e, "video")}
                                className="action-button"
                            >
                                <FiVideo className="icon" />
                                Video
                            </button>
                        )}
                        {showAction("subscribe") && (
                            <button
                                onClick={e => changePanelMenu(e, "subscribe")}
                                className="action-button"
                            >
                                <CiMail className="icon" />
                                Subscribe
                            </button>
                        )}
                    </div>
                </div>
                <div className="action-menu-panel">
                    {panelAction === "write" && (
                        <div className="write-action-menu">
                            {/* message showing tags */}
                            <div
                                ref={tagMenuContainerRef}
                                className="input-tag-menu shadow hidden"
                            >
                                {searchedParticipants.map(participant => (
                                    <p
                                        onClick={() =>
                                            onTagPersonaSelect(participant)
                                        }
                                        key={participant.id}
                                        className="text-blue-500 py-2 font-semibold px-4 cursor-pointer hover:bg-gray-50"
                                    >
                                        {participant.name}
                                    </p>
                                ))}
                            </div>
                            <div className="input h-full">
                                <div
                                    className="input-element"
                                    ref={messageInputRef}
                                    onKeyUp={handleKeyDown}
                                    contentEditable={
                                        props.isTextDisabled !== true
                                    }
                                    placeholder="What is on your mind? If you want to save as a note. Click here"
                                />
                            </div>
                            <div className="input-action relative">
                                <button
                                    onClick={toggleEmojiPicker}
                                    className="input-actions-button"
                                >
                                    <PiSmileyLight className={"icon"} />
                                </button>
                                <button className="input-actions-button">
                                    <IoIosAttach className={"icon"} />
                                </button>
                                {/* emoji picker */}
                                <div
                                    ref={emojiPickerRef}
                                    className="absolute hidden top-[140%] right-0"
                                >
                                    <EmojiPicker onEmojiClick={onEmojiSelect} />
                                </div>
                            </div>
                        </div>
                    )}
                    {panelAction === "voice" && (
                        <div className="flex justify-end">
                            <div className="w-[50%] bg-white border rounded-[8px]">
                                {/* <div className="h-[45px] px-2 bg-white grid grid-cols-[50px_auto]">
                                    <div className="flex items-center justify-center pt-[20px]">
                                        <p className="font-semibold text-blue-500">
                                            {formatRecordingTime(
                                                audioRecorderPlayTime,
                                            )}
                                        </p>
                                    </div>
                                    <div className="pt-[15px]">
                                         {audioRecorder.mediaRecorder && (
                                            <LiveAudioVisualizer
                                                mediaRecorder={
                                                    soundRecorder.previewAudioStream as unknown as MediaRecorder
                                                }
                                                width={320}
                                                height={30}
                                            />
                                        )}
                                    </div>
                                </div> */}
                                <div className="flex justify-between py-2 px-4">
                                    <div className="flex items-center">
                                        <Button
                                            variant="link"
                                            borderRadius={"50%"}
                                            h={"35px"}
                                            w={"35px"}
                                            p={"6px"}
                                            onClick={cancelAudioRecording}
                                        >
                                            <AiOutlineDelete
                                                className="text-red-500"
                                                size="20px"
                                            />
                                        </Button>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="flex items-center justify-center w-[68px] gap-[10px]">
                                            <BsMic
                                                size="16px"
                                                className="text-blue-500"
                                            />
                                            <p className="font-semibold text-blue-500">
                                                {formatRecordingTime(
                                                    audioRecorderPlayTime
                                                )}
                                            </p>
                                        </div>
                                        {/* <Button
                                            variant="link"
                                            borderRadius={"50%"}
                                            h={"35px"}
                                            w={"35px"}
                                            p={"6px"}
                                            onClick={() =>
                                                soundRecorder.
                                            }
                                        >
                                            {audioRecorder.isPaused ? (
                                                <BsMic size="20px" />
                                            ) : (
                                                <BiPauseCircle size="28px" />
                                            )}
                                        </Button> */}
                                    </div>
                                    <div className="flex items-center">
                                        <button
                                            onClick={completeAudioRecording}
                                            className="bg-blue-500 rounded-full h-[35px] w-[35px] flex items-center justify-center"
                                        >
                                            <IoSendSharp
                                                className="text-white"
                                                size="16px"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {panelAction === "video" && (
                        <div className="flex justify-end">
                            {videoRecorder.status === "acquiring_media" && (
                                <div className="h-[35px] flex bg-white border rounded-[8px] items-center px-6">
                                    Starting Record...
                                </div>
                            )}
                            {videoRecorder.status === "recording" && (
                                <div className="video-recorder">
                                    {isVideoInitialized && (
                                        <div className="video-recorder-header absolute flex items-center gap-2 top-0 left-0 right-0 pt-2 z-10 px-5">
                                            <p className="recording-pulse"></p>
                                            <p className="text-red-500 text-[12px]">
                                                Recording
                                            </p>
                                        </div>
                                    )}
                                    <video
                                        ref={videoRecorderPreviewRef}
                                        className="video-preview"
                                        autoPlay
                                        onPlay={() =>
                                            setIsVideoInitialized(true)
                                        }
                                    ></video>
                                    {isVideoInitialized && (
                                        <div className="video-recorder-footer absolute flex justify-between bottom-0 left-0 right-0 pb-6 z-10 px-5">
                                            <button
                                                onClick={cancelVideoRecording}
                                                className="bg-red-500 rounded-full h-[35px] w-[35px] flex items-center justify-center"
                                            >
                                                <AiOutlineDelete
                                                    className="text-white"
                                                    size="20px"
                                                />
                                            </button>
                                            <button
                                                onClick={completeVideoRecording}
                                                className="bg-blue-500 rounded-full h-[35px] w-[35px] flex items-center justify-center"
                                            >
                                                <IoSendSharp
                                                    className="text-white"
                                                    size="16px"
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="plus-action">
                <button
                    onClick={toggleActionPanel}
                    className={`plus-btn ${
                        isActionMenuOpen ? "is-menu-open" : ""
                    }`}
                >
                    <LordIcon
                        src="/lord-icons/plus-add.json"
                        trigger="hover"
                        colors={{
                            primary: "#fff",
                            secondary: "#000"
                        }}
                        stroke={40}
                        size={60}
                    />
                </button>
            </div>
        </div>
    );
};

export default RoomMessageInput;
