import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Input,
    Select,
    useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsFolderPlus } from "react-icons/bs";
// import { useSelector } from "react-redux";
import { type FolderModel } from "../models/folder.model";
// import { SearchModel } from "../models/search.model";
import { useHttpClient } from "../utils/http.utils";

interface SaveResponseFormProps {
    id: string;
    isOpen?: boolean;
    onClose?: () => void;
}

export default function SaveResponseForm(props: SaveResponseFormProps) {
    /**
     * Modal open, close state and functions
     * @see https://chakra-ui.com/docs/components/modal/usage
     */
    const saveDisclosure = useDisclosure();
    const newFolderDisclosure = useDisclosure();
    const folders = [];
    const [selectedFolder, setSelectedFolder] = useState<string>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const toast = useToast();
    const { saveToFolderApi } = useHttpClient();

    const closeSaveModal = () => {
        if (isSaving) {
            return;
        }
        saveDisclosure.onClose();
        // emit an event to the parent, so it changes it's state
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    /**
     * !important: Do not add saveDisclosure to the dependency array.
     * This hook listen to the props passed from the parent to either
     * open or close the modal
     */
    useEffect(() => {
        if (props.isOpen) {
            saveDisclosure.onOpen();
        } else {
            closeSaveModal();
        }
    }, [props.isOpen]);

    const closeFormModal = () => {
        if (isSaving) {
            return;
        }
        newFolderDisclosure.onClose();
    };

    const closeAllModals = () => {
        if (isSaving) {
            return;
        }
        saveDisclosure.onClose();
        newFolderDisclosure.onClose();
        // emit an event to the parent, so it changes it's state
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const onSaveFolder = () => {
        // Return if folder name is empty
        if (!selectedFolder) {
            toast({
                title: "Please specify a folder",
                duration: 1500
            });
            return;
        }
        // Save folder
        setIsSaving(true);
        saveToFolderApi(props.id, selectedFolder)
            .then(() => {
                toast({
                    title: "Saved",
                    status: "success",
                    duration: 1500
                });
            })
            .finally(() => {
                closeAllModals();
                setIsSaving(false);
            });
    };

    return (
        <>
            {/* Save modal */}
            <Modal
                size="lg"
                isOpen={saveDisclosure.isOpen}
                onClose={closeSaveModal}
                closeOnOverlayClick={!isSaving}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Save Response</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Select Folder</FormLabel>
                            <Select
                                onChange={e =>
                                    setSelectedFolder(e.target.value)
                                }
                                placeholder="Select Folder"
                            >
                                {folders.map(
                                    (folder: FolderModel, index: number) => (
                                        <option key={index} value={folder.name}>
                                            {folder.name}
                                        </option>
                                    )
                                )}
                            </Select>
                        </FormControl>
                        <Button
                            onClick={newFolderDisclosure.onOpen}
                            size="sm"
                            colorScheme="blue"
                            variant="ghost"
                            mt={4}
                        >
                            <BsFolderPlus />
                            &nbsp;Create New Folder
                        </Button>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={closeSaveModal}>
                            Close
                        </Button>
                        <Button
                            isLoading={isSaving}
                            loadingText="Saving.."
                            onClick={onSaveFolder}
                            color="white"
                            style={{ backgroundColor: "#000" }}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* New Folder */}
            <Modal
                isOpen={newFolderDisclosure.isOpen}
                onClose={closeFormModal}
                closeOnOverlayClick={!isSaving}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Folder</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>New Folder Name</FormLabel>
                            <Input
                                onChange={e =>
                                    setSelectedFolder(e.target.value)
                                }
                                placeholder="New Folder Name"
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={closeFormModal}>
                            Close
                        </Button>
                        <Button
                            isLoading={isSaving}
                            loadingText="Saving.."
                            onClick={onSaveFolder}
                            color="white"
                            style={{ backgroundColor: "#000" }}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
