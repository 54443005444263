// import { string } from "yup";
// import React from "react";
import { type PersonaModel } from "../models/persona.model";

export function convertHeaderStringToList(str: string) {
    const pattern = /\[([^\]]+)]/g;
    const matches = str.match(pattern);

    if (!matches) {
        return [str];
    }

    const result: string[] = [];
    let lastIndex = 0;

    for (const match of matches) {
        const index = str.indexOf(match, lastIndex);

        if (index > lastIndex) {
            result.push(str.substring(lastIndex, index).trim());
        }

        result.push(match);
        lastIndex = index + match.length;
    }

    if (lastIndex < str.length) {
        result.push(str.substring(lastIndex).trim());
    }

    return result;
}

export function capitalizeWord(word: string) {
    if (!word) {
        return "";
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
}

// Define a function that takes an array of strings as a parameter
export function removeDuplicates(list: string[]) {
    // Create an empty object to store the unique strings
    const unique: { [key: string]: any } = {};
    // Loop through the array
    for (let i = 0; i < list.length; i++) {
        // If the string is not already in the object, add it as a key with a value of true
        if (!unique[list[i]]) {
            unique[list[i]] = true;
        }
    }
    // Return the keys of the object as an array
    return Object.keys(unique);
}

// Function to remove duplicates from an array of objects
export function removeDuplicatesByProperty(arr: any[], key: string) {
    const uniqueValues = new Map();

    for (const obj of arr) {
        const value = obj[key];
        if (!uniqueValues.has(value)) {
            uniqueValues.set(value, obj);
        }
    }

    return Array.from(uniqueValues.values());
}

// A function that takes a string as input and returns true if it is a valid url, false otherwise
export function isValidUrl(url: string) {
    const regex =
        /^(https?|ftp):\/\/([a-z0-9-]+(\.[a-z0-9-]+)+|localhost)(:\d+)?(\/[^\s]*)?$/i;
    return regex.test(url);
}

export function convertHalfCharactersToBold(inputString: string) {
    // Split the input string into an array of words
    const words = inputString.split(" ");

    // Iterate through each word in the array
    const boldWords = words.map(word => {
        // Check if the word has at least two characters
        if (word.length >= 2) {
            const wordLength = word.length;
            const halfLength = Math.ceil(wordLength / 2);
            // Get the first two characters of the word and wrap them with <b> tags
            const boldedChars = `<strong>${word.substring(0, halfLength)}</strong>`;

            // Combine the bolded characters with the rest of the word
            const restOfWord = word.substring(halfLength);
            return boldedChars + restOfWord;
        }

        // Return the word as is if it has less than two characters
        return word;
    });

    // Join the array of bolded words back into a string
    const result = boldWords.join(" ");

    return result;
}

export function getMemoryFromNamespaceString(namespace: string) {
    if (!namespace) {
        return { sector: "", location: "" };
    }
    const [_, sector, location] = namespace.split("-axbyzc-");

    return { sector, location };
}

export function getReactSelectOptionsList(
    arr: any[][],
    keyProp: string,
    valueProp: string
): Array<{ value: string; label: string }> {
    return arr.map(item => {
        return {
            label: capitalizeWord(item[keyProp] as string),
            value: item[valueProp]
        };
    });
}

export function getReactSelectOptionMap(
    arr: any[],
    keyProp: string,
    valueProp: string,
    value: string
): { value: string; label: string } {
    const item = arr.find((item: any) => item[keyProp] === value);
    return {
        label: capitalizeWord(item[keyProp] as string),
        value: item[valueProp]
    };
}

export function formatRecordingTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
}

export function getPersonaFullName(persona: PersonaModel) {
    if (persona.first_name && persona.last_name) {
        return persona.first_name + " " + persona.last_name;
    } else {
        return persona.name ?? "";
    }
}

export function replaceValues(
    inputStr: string,
    startDelim: string,
    endDelim: string,
    replaceObj: { [key: string]: string }
) {
    let returnStr = inputStr;
    for (const key in replaceObj) {
        returnStr = returnStr.replace(
            new RegExp(`${startDelim}${key}${endDelim}`, "g"),
            replaceObj[key]
        );
    }

    return returnStr;
}

export function extractLinks(text?: string): string[] | null {
    if (!text) {
        return null;
    }

    const urlRegex =
        /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g;
    return text.match(urlRegex);
}

export function formatFileSize(bytes: number) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
        return "0 Bytes";
    }
    const i = parseInt(
        Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
        10
    );
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
}

export function getFileNameAndExtension(file: File | string) {
    // Get the file name and split it to get the file extension
    if (typeof file === "string") {
        const fileName = file.split(".");
        return { extension: fileName.pop()?.toLowerCase(), name: fileName[0] };
    } else {
        const fileName = file.name.split(".");
        return { extension: fileName.pop()?.toLowerCase(), name: fileName[0] };
    }
}

export function truncateText(text: string, length = 200) {
    if (text.length < length) {
        return text;
    }
    return text.slice(0, length) + "..";
}
