import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface NotificationsState {
    isFetching: boolean;
    data: any[];
}

const initialState: NotificationsState = {
    isFetching: false,
    data: []
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setIsNotificationsFetching(state, payload: PayloadAction<boolean>) {
            state.isFetching = payload.payload;
        },
        setNotificationState(state, payload: PayloadAction<any>) {
            state.data = payload.payload;
        },
        removeNotificationFromState: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(
                notification => notification.id !== action.payload
            );
        }
    }
});

export const {
    setNotificationState,
    setIsNotificationsFetching,
    removeNotificationFromState
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
