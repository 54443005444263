import { useCallback, useState } from "react";

type CenteredTreeReturn = [
    translate: { x: number; y: number },
    containerRef: (containerElem: any) => void
];

export const useCenteredTree = (
    defaultTranslate = { x: 0, y: 0 }
): CenteredTreeReturn => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const containerRef = useCallback(containerElem => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 1.5, y: height / 1.5 });
        }
    }, []);
    return [translate, containerRef];
};
