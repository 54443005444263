import React, { useEffect, useRef, useState } from "react";
import { Button, SkeletonCircle } from "@chakra-ui/react";
import Player from "@vimeo/player";
import { LuBookmark, LuLink } from "react-icons/lu";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { formatDate } from "../utils/date.utils";
import { NavLink } from "react-router-dom";
import { TbLayoutNavbarExpand } from "react-icons/tb";
import { useSelector } from "react-redux";

interface VideoPlayerProps {
    videoId: number;
    loading?: boolean;
    isInGroup: boolean;
    roomId?: string;
    messageId?: string;
    isOwnMessage: boolean;
    hideThread?: boolean;
    otherProps?: any;
    onRateResponse: () => Promise<boolean>;
}

const RoomVideoMessageCard: React.FC<VideoPlayerProps> = ({
    videoId,
    onRateResponse,
    hideThread,
    isOwnMessage,
    isInGroup,
    roomId,
    messageId,
    otherProps
}: VideoPlayerProps) => {
    const [hasImageLoaded, setHasImageLoaded] = useState(false);
    const [threadCount, _setThreadCount] = useState<number>(0);
    const [sentiment, setSentiment] = useState("");
    const { user } = useSelector((state: any) => state.auth);
    const [messageAuthor, setMessageAuthor] = useState<{
        name: string;
        profile_picture?: string;
        type: string;
    }>();

    const playerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const options = {
            id: videoId,
            loop: false,
            autoplay: false,
            width: 500,
            height: 400
        };

        if (playerRef.current !== null) {
            const player = new Player(playerRef.current, options);

            player.on("play", () => {
                console.log("play");
            });
        }
    }, [videoId]);

    useEffect(() => {
        console.log(otherProps);
        if (isOwnMessage) {
            setMessageAuthor({
                name: user.name,
                profile_picture: user.profile_picture,
                type: "user"
            });
        } else if (otherProps.user) {
            setMessageAuthor({
                name: otherProps.user.name,
                profile_picture: otherProps.user.profile_picture,
                type: "user"
            });
        } else if (otherProps.persona) {
            setMessageAuthor({
                name:
                    otherProps.persona.first_name +
                    " " +
                    otherProps.persona.last_name,
                profile_picture: otherProps.persona.image,
                type: "persona"
            });
        }
    }, []);

    const changeSentiment = (type: string) => {
        setSentiment(type);
    };

    /**
     * When the thumbs up button is clicked
     */
    const ratePersonaResponse = async () => {
        if (typeof onRateResponse === "function") {
            const status = await onRateResponse();
            setSentiment("1");
            // If the status from the parent is false, change it back to a button
            if (!status) {
                setSentiment("");
            }
        }
    };

    return isOwnMessage ? (
        <div className="room-card-container own-message">
            <div className="trailing"></div>
            <div className="flex justify-end">
                <div
                    style={{ border: "1px solid #bbdefb" }}
                    className="bg-[#f3f9ff] relative overflow-hidden rounded-[8px] w-[500px] px-[0]"
                >
                    <div ref={playerRef} className="w-full"></div>
                    <div className="card-footer mt-[20px]  px-[14px] pb-[12px]">
                        <div className="date">
                            {/* <p className="card-date text-slate-400">{capitalizeWord(getMemoryFromNamespaceString(props.data.check_list as string).sector || '')}</p> */}
                            {/* <p className="card-date text-slate-400">Montague Adameve</p> */}
                            <p className="card-date text-slate-400">
                                {formatDate("", "D MMMM YYYY")}
                            </p>
                        </div>
                        <div className="card-footer-actions">
                            {sentiment === "1" && (
                                <p className="sentiment-message gap-1.5 pr-4 text-slate-500">
                                    <FiThumbsUp className="icon positive" />
                                    <span>You rated "Amazing"</span>
                                </p>
                            )}
                            {sentiment === "0" && (
                                <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                    <FiThumbsDown className="icon negative" />
                                    <span>You rated "Could be better"</span>
                                </p>
                            )}

                            {!sentiment && (
                                <>
                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={ratePersonaResponse}
                                        colorScheme="blue"
                                        size="sm"
                                        // variant={props.data.Sentiment === '1' ? 'solid' : 'outline'}
                                        variant="ghost"
                                    >
                                        <FiThumbsUp className="icon positive" />
                                    </Button>
                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={() => changeSentiment("0")}
                                        colorScheme="red"
                                        size="sm"
                                        // variant={props.data.Sentiment === '0' ? 'solid' : 'outline'}
                                        variant="ghost"
                                    >
                                        <FiThumbsDown className="icon negative" />
                                    </Button>
                                </>
                            )}
                            <Button
                                mr={1}
                                className="icon-buttons"
                                // onClick={emitSaveResponse}
                                variant="ghost"
                            >
                                <LuBookmark className="icon save-icon" />
                            </Button>

                            <Button
                                mr={1}
                                className="icon-buttons"
                                colorScheme="gray"
                                variant="ghost"
                            >
                                <LuLink className="icon rotate" size="24px" />
                            </Button>

                            {!hideThread && (
                                <NavLink
                                    to={
                                        isInGroup
                                            ? `/room-group/${roomId}/thread/${messageId}`
                                            : `/room/${roomId}/thread/${messageId}`
                                    }
                                >
                                    <Button
                                        title={
                                            threadCount > 0
                                                ? `Thread (${threadCount})`
                                                : "Start thread"
                                        }
                                        colorScheme={
                                            threadCount > 0 ? "brand" : "gray"
                                        }
                                        size="sm"
                                        variant={
                                            threadCount > 0
                                                ? "solid"
                                                : "outline"
                                        }
                                    >
                                        <TbLayoutNavbarExpand className="icon" />
                                        &nbsp;
                                        {threadCount > 0
                                            ? `Thread (${threadCount})`
                                            : "Start thread"}
                                    </Button>
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="leading leading-end">
                <img
                    src={user.profile_picture || "/user_avatar.png"}
                    className={`${hasImageLoaded ? "block" : "hidden"}`}
                    onLoad={() => setHasImageLoaded(true)}
                    alt=""
                />
                {!hasImageLoaded && <SkeletonCircle size="30" />}
            </div>
        </div>
    ) : (
        <div className="room-card-container">
            <div className="leading">
                <img
                    src={messageAuthor?.profile_picture || "/user_avatar.png"}
                    className={`${hasImageLoaded ? "block" : "hidden"}`}
                    onLoad={() => setHasImageLoaded(true)}
                    alt=""
                />
                {!hasImageLoaded && <SkeletonCircle size="30" />}
            </div>
            <div className="relative overflow-hidden rounded-[8px] w-[550px]">
                <div ref={playerRef} className="w-full"></div>
                <div className="card-footer mt-[20px]  px-[14px] pb-[12px]">
                    <div className="date">
                        {/* <p className="card-date text-slate-400">{capitalizeWord(getMemoryFromNamespaceString(props.data.check_list as string).sector || '')}</p> */}
                        {/* <p className="card-date text-slate-400">Montague Adameve</p> */}
                        <p className="card-date text-slate-400">
                            {formatDate("", "D MMMM YYYY")}
                        </p>
                    </div>
                    <div className="card-footer-actions">
                        {sentiment === "1" && (
                            <p className="sentiment-message gap-1.5 pr-4 text-slate-500">
                                <FiThumbsUp className="icon positive" />
                                <span>You rated "Amazing"</span>
                            </p>
                        )}
                        {sentiment === "0" && (
                            <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                <FiThumbsDown className="icon negative" />
                                <span>You rated "Could be better"</span>
                            </p>
                        )}

                        {!sentiment && (
                            <>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    onClick={ratePersonaResponse}
                                    colorScheme="blue"
                                    size="sm"
                                    // variant={props.data.Sentiment === '1' ? 'solid' : 'outline'}
                                    variant="ghost"
                                >
                                    <FiThumbsUp className="icon positive" />
                                </Button>
                                <Button
                                    mr={1}
                                    className="icon-buttons"
                                    onClick={() => changeSentiment("0")}
                                    colorScheme="red"
                                    size="sm"
                                    // variant={props.data.Sentiment === '0' ? 'solid' : 'outline'}
                                    variant="ghost"
                                >
                                    <FiThumbsDown className="icon negative" />
                                </Button>
                            </>
                        )}
                        <Button
                            mr={1}
                            className="icon-buttons"
                            // onClick={emitSaveResponse}
                            variant="ghost"
                        >
                            <LuBookmark className="icon save-icon" />
                        </Button>

                        <Button
                            mr={1}
                            className="icon-buttons"
                            colorScheme="gray"
                            variant="ghost"
                        >
                            <LuLink className="icon rotate" size="24px" />
                        </Button>

                        {!hideThread && (
                            <NavLink
                                to={
                                    isInGroup
                                        ? `/room-group/${roomId}/thread/${messageId}`
                                        : `/room/${roomId}/thread/${messageId}`
                                }
                            >
                                <Button
                                    title={
                                        threadCount > 0
                                            ? `Thread (${threadCount})`
                                            : "Start thread"
                                    }
                                    colorScheme={
                                        threadCount > 0 ? "brand" : "gray"
                                    }
                                    size="sm"
                                    variant={
                                        threadCount > 0 ? "solid" : "outline"
                                    }
                                >
                                    <TbLayoutNavbarExpand className="icon" />
                                    &nbsp;
                                    {threadCount > 0
                                        ? `Thread (${threadCount})`
                                        : "Start thread"}
                                </Button>
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomVideoMessageCard;
