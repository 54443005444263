import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    useDisclosure
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import UsageChart, { type LineData } from "../UsageChart";
import ReactSelect from "react-select";
import { useSelector } from "react-redux";
import { getPersonaFullName } from "../../utils/strings.utils";
import CreditTopUpForm from "../payment/CreditTopUpForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { useHttpClient } from "../../utils/http.utils";
import { type PersonaModel } from "../../models/persona.model";
import { type AccountState } from "../../store/slices/account.slice";
import { formatCurrency } from "../../utils/number.utils";
import exportFromJSON from "export-from-json";
import { formatDate } from "../../utils/date.utils";
import { config } from "../../config";

interface CreditsModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

const CreditsModal: React.FC<CreditsModalProps> = props => {
    const { listUserTransactionsApi, listCreditUsageApi } = useHttpClient();
    const creditDis = useDisclosure();
    const personaState = useSelector((state: any) => state.personas);
    const accountState: AccountState = useSelector(
        (state: any) => state.account
    );
    const personas: PersonaModel[] = personaState.personas;
    const [personaOptions, setPersonaOptions] = useState<any[]>([]);
    const [showTopUpForm, setShowTopUpForm] = useState(false);
    const [userTransactions, setUserTransactions] = useState<any[]>([]);
    const [creditUsage, setCreditUsage] = useState<any[]>([]);
    const personaOptionsTempList = useRef<any[]>([]);
    const [lineData, setLineData] = useState<LineData[]>([]);
    const [selectedPersona, setSelectedPersona] = useState<string>("all");
    const [selectedFilter, setSelectedFilter] = useState<string>("Words");

    useEffect(() => {
        // Set persona options
        personaOptionsTempList.current = personas.map(
            (persona: PersonaModel) => ({
                label: getPersonaFullName(persona),
                value: persona.id
            })
        );
        personaOptionsTempList.current.unshift({ value: "all", label: "All" });

        setPersonaOptions(personaOptionsTempList.current);
        // set the line data
        setLineData([
            { id: "user-usage", color: "rgba(0, 0, 0, 0.4)", data: [] }
        ]);
    }, []);

    const loadTransactionsData = async () => {
        const transactions: any[] = await listUserTransactionsApi();

        setUserTransactions(transactions);

        const usageData: any[] = await listCreditUsageApi();

        // load chart Data
        loadChartData(usageData, {
            personaId: selectedPersona,
            filter: selectedFilter
        });
        // set to state
        setCreditUsage(usageData);
        // set chart data
    };

    const loadChartData = (
        data,
        { personaId = selectedPersona, filter = selectedFilter }
    ) => {
        const creditUsageData = data || creditUsage;
        let usageList: any[] = [];
        let vertices: Array<{ x: string; y: number }> = [];

        if (personaId !== "all") {
            usageList = creditUsageData.filter(
                usage => usage.external_entity_id === personaId
            );
        } else {
            usageList = creditUsageData;
        }
        // start filtering
        vertices = usageList.map(record => {
            // find persona
            if (filter.toLowerCase() === "words") {
                const persona = personas.find(
                    persona => persona.id === record.external_entity_id
                );
                // check if ther person
                const pricePerWord =
                    persona?.pricing_per_word || config.creditPerWord;
                return {
                    x: formatDate(record.created_at as number, "MMM DD, YY"),
                    y: Number(record.amount) / Number(pricePerWord)
                };
            } else {
                return {
                    x: formatDate(record.created_at as number, "MMM DD, YY"),
                    y: record.amount
                };
            }
        });

        setLineData([
            { id: "user-usage", color: "rgba(0, 0, 0, 0.4)", data: vertices }
        ]);
    };

    const onPersonaSelect = (personaId: string) => {
        loadChartData(creditUsage, { personaId, filter: selectedFilter });
        setSelectedPersona(personaId);
    };

    const onFilterSelect = (filter: string) => {
        loadChartData(creditUsage, { personaId: selectedPersona, filter });
        setSelectedFilter(filter);
    };

    useEffect(() => {
        if (props.isOpen) {
            // load data
            loadTransactionsData();
            creditDis.onOpen();
        } else {
            creditDis.onClose();
        }
        /**
         * !!important
         * DO NOT ADD `creditDis  and `loadTransactionsData` to the array of dependencies, othewise
         * we will infinite renders
         */
    }, [props.isOpen]);

    const closeViewModal = () => {
        creditDis.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const downloadTransactions = () => {
        const downloadPayload = userTransactions.map(transaction => ({
            "Ledger Entry": transaction.ledger_entry,
            "Amount (T$)": transaction.amount,
            Date: formatDate(transaction.created_at as number, "MM/DD/YYYY")
        }));

        const fileName = "neo_transaction_history";
        const exportType = exportFromJSON.types.csv;

        exportFromJSON({ data: downloadPayload, fileName, exportType });
    };

    return (
        <div className="">
            <Modal
                size="3xl"
                isOpen={creditDis.isOpen}
                onClose={closeViewModal}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>Credits</ModalHeader>
                    <ModalBody pt={0}>
                        <div className="flex flex-col md:flex-row md:justify-between h-[180px] md:h-[100px]">
                            <div className="header">
                                <div className="header-tile">
                                    {Number(accountState.creditBalance) >= 0 ? (
                                        <h1 className="title">
                                            T
                                            {formatCurrency(
                                                accountState.creditBalance
                                            )}
                                        </h1>
                                    ) : (
                                        <Skeleton
                                            width="80px"
                                            height="30px"
                                        ></Skeleton>
                                    )}
                                    <Button
                                        onClick={() => setShowTopUpForm(true)}
                                        colorScheme="blue"
                                        variant="ghost"
                                    >
                                        Top up
                                    </Button>
                                    <Button
                                        onClick={downloadTransactions}
                                        colorScheme="blue"
                                        variant="ghost"
                                    >
                                        Download History
                                    </Button>
                                </div>
                            </div>
                            <div className={"flex gap-2 items-center"}>
                                <div className="w-32">
                                    <ReactSelect
                                        placeholder="Persona"
                                        className="arin-react-select-container"
                                        classNamePrefix="arin-react-select"
                                        defaultValue={personaOptions[0]}
                                        options={personaOptions}
                                        onChange={val =>
                                            onPersonaSelect(
                                                (val?.value as string) || "all"
                                            )
                                        }
                                    />
                                </div>
                                <ReactSelect
                                    placeholder="Persona"
                                    className="arin-react-select-container"
                                    classNamePrefix="arin-react-select"
                                    defaultValue={{
                                        value: "words",
                                        label: "Words"
                                    }}
                                    options={[
                                        { value: "Words", label: "Words" },
                                        { value: "T$", label: "T$" }
                                    ]}
                                    onChange={val =>
                                        onFilterSelect(val?.value || "Words")
                                    }
                                />
                            </div>
                        </div>
                        <UsageChart
                            xAxisLabel={selectedFilter}
                            lineData={lineData}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ stripe }) => (
                        <CreditTopUpForm
                            stripe={stripe!}
                            isOpen={showTopUpForm}
                            onClose={() => setShowTopUpForm(false)}
                        />
                    )}
                </ElementsConsumer>
            </Elements>
        </div>
    );
};

export default CreditsModal;
