import { useState } from "react";
import { useHttpClient } from "../utils/http.utils";
import type { PersonaModel } from "../models/persona.model";
import type { UserModel } from "../models/user.model";
import type { TreeItem } from "../types/genealogy.types";

export const mockData = [
    {
        id: 1,
        name: "Item A",
        parent_list: [],
        username: "user1",
        email: "user1@example.com",
        createdAt: "2022-01-01T10:00:00Z"
    },
    {
        id: 2,
        name: "Item B",
        parent_list: [
            {
                id: 1,
                name: "Item A",
                parent_list: [],
                username: "user1",
                email: "user1@example.com",
                createdAt: "2022-01-01T10:00:00Z"
            }
        ],
        username: "user2",
        email: "user2@example.com",
        createdAt: "2022-01-02T14:30:00Z"
    },
    {
        id: 3,
        name: "Item C",
        parent_list: [
            {
                id: 1,
                name: "Item A",
                parent_list: [],
                username: "user1",
                email: "user1@example.com",
                createdAt: "2022-01-01T10:00:00Z"
            }
        ],
        username: "user3",
        email: "user3@example.com",
        createdAt: "2022-01-03T08:45:00Z"
    },
    {
        id: 4,
        name: "Item D",
        parent_list: [],
        username: "user4",
        email: "user4@example.com",
        createdAt: "2022-01-04T12:15:00Z"
    },
    {
        id: 5,
        name: "Item E",
        parent_list: [
            {
                id: 4,
                name: "Item D",
                parent_list: [],
                username: "user4",
                email: "user4@example.com",
                createdAt: "2022-01-04T12:15:00Z"
            }
        ],
        username: "user5",
        email: "user5@example.com",
        createdAt: "2022-01-05T16:20:00Z"
    },
    {
        id: 6,
        name: "Item F",
        parent_list: [
            {
                id: 4,
                name: "Item D",
                parent_list: [],
                username: "user4",
                email: "user4@example.com",
                createdAt: "2022-01-04T12:15:00Z"
            },
            {
                id: 5,
                name: "Item E",
                parent_list: [
                    {
                        id: 4,
                        name: "Item D",
                        parent_list: [],
                        username: "user4",
                        email: "user4@example.com",
                        createdAt: "2022-01-04T12:15:00Z"
                    }
                ],
                username: "user5",
                email: "user5@example.com",
                createdAt: "2022-01-05T16:20:00Z"
            }
        ],
        username: "user6",
        email: "user6@example.com",
        createdAt: "2022-01-06T09:30:00Z"
    },
    {
        id: 20,
        name: "Item L",
        parent_list: [
            {
                id: 2,
                name: "Item B",
                parent_list: [
                    {
                        id: 1,
                        name: "Item A",
                        parent_list: [],
                        username: "user1",
                        email: "user1@example.com",
                        createdAt: "2022-01-01T10:00:00Z"
                    }
                ],
                username: "user2",
                email: "user2@example.com",
                createdAt: "2022-01-02T14:30:00Z"
            }
        ],
        username: "user20",
        email: "user20@example.com",
        createdAt: "2022-01-02T14:30:00Z"
    },
    {
        id: 21,
        name: "Item M",
        parent_list: [
            {
                id: 7,
                name: "Item G",
                parent_list: [],
                dummy_user: true,
                username: "dummy_user1",
                email: "dummy1@example.com",
                createdAt: "2022-01-07T11:45:00Z"
            }
        ],
        username: "user21",
        email: "user21@example.com",
        createdAt: "2022-01-02T14:30:00Z"
    },
    {
        id: 7,
        name: "Item G",
        parent_list: [],
        dummy_user: true,
        username: "dummy_user1",
        email: "dummy1@example.com",
        createdAt: "2022-01-07T11:45:00Z"
    },
    {
        id: 8,
        name: "Item H",
        parent_list: [],
        dummy_user: true,
        username: "dummy_user2",
        email: "dummy2@example.com",
        createdAt: "2022-01-07T11:45:00Z"
    }
];

export const useGraphTreeFormatter = () => {
    const [allPersonas, setAllPersonas] = useState<
        Array<PersonaModel | UserModel>
    >([]);
    const { listUsersApi } = useHttpClient();

    // Api call
    const getUsersWhoCreatedPersonas = async (
        id?: string[]
    ): Promise<UserModel[]> => {
        const { data } = await listUsersApi({ id });
        return data;
    };

    const convertArrayToTree = async (personas: PersonaModel[]) => {
        let i: number;
        const map: { [key: string]: TreeItem } = {};
        const userCreatedPersonas: string[] = [];
        const tree: TreeItem[] = [];
        let personaOrUserList: Array<PersonaModel | UserModel> = [];

        // Check is the items in the personas array have parents.
        // if they dont have parents, add their IDs to the userCreatedPersonas array
        for (i = 0; i < personas.length; i += 1) {
            const parentListLength = personas[i].parent_list?.length;
            if (
                parentListLength &&
                parentListLength <= 0 &&
                !userCreatedPersonas.includes(personas[i].user!)
            ) {
                userCreatedPersonas.push(personas[i].user!);
            }
        }

        // use the userCreatedPersonas array to make an API call and get all the users
        // NB: To use the mockdata, this line has to be commented out
        const userCreators = await getUsersWhoCreatedPersonas(
            userCreatedPersonas
        ).then(res => res);
        personaOrUserList = [...personas, ...userCreators];

        // Initialize the map
        for (i = 0; i < personaOrUserList.length; i += 1) {
            const currentPersona = personaOrUserList[i];
            map[currentPersona.id!] = currentPersona;

            map[currentPersona.id!].children = [];
            map[currentPersona.id!].isChild = false;
        }

        for (i = 0; i < personaOrUserList.length; i += 1) {
            // If the persona has parents, update the children array for the parents in the map
            const parentListLength = (personaOrUserList[i] as PersonaModel)
                .parent_list?.length;
            if (parentListLength && parentListLength > 0) {
                const firstParent = (personaOrUserList[i] as PersonaModel)
                    .parent_list![0] as PersonaModel;
                const lastParent =
                    parentListLength > 1 &&
                    ((personaOrUserList[i] as PersonaModel).parent_list![
                        parentListLength - 1
                    ] as PersonaModel);
                const updatedFirstPersonaChildren = [
                    ...map[firstParent.id!].children!,
                    map[personaOrUserList[i].id!]
                ];
                map[firstParent.id!].children = updatedFirstPersonaChildren;

                if (lastParent && firstParent !== lastParent) {
                    console.log(lastParent);
                    const updatedPersonChildren = [
                        ...map[lastParent.id!].children!,
                        map[personaOrUserList[i].id!]
                    ];
                    map[lastParent.id!].children = updatedPersonChildren;
                }

                // Toggle the isChild value of the current persona in the map
                map[personaOrUserList[i].id!].isChild = true;
            } else if (
                parentListLength &&
                parentListLength <= 0 &&
                map[(personaOrUserList[i] as PersonaModel).user!]
            ) {
                // If the persona has no parents, and was created by a user
                // Update the children array for the user in the map
                map[(personaOrUserList[i] as PersonaModel).user!].children = [
                    ...map[(personaOrUserList[i] as PersonaModel).user!]
                        .children!,
                    map[personaOrUserList[i].id!]
                ];
                // Toggle the isChild value of the current persona in the map
                map[personaOrUserList[i].id!].isChild = true;
            }
        }

        setAllPersonas(personaOrUserList);
        // Recontruct the tree, only the personas who are not children should appear at top level
        Object.entries(map).forEach(([_, entry]) => {
            const treeRoot: TreeItem = {
                name: "Root",
                children: [] as TreeItem[]
            };
            if (entry.isChild === false) {
                treeRoot.children = [...treeRoot.children!, entry];
                tree.push(treeRoot);
            }
        });

        return { tree, personaOrUserList };
    };

    return { convertArrayToTree, allPersonas };
};
