import { HiOutlineDownload } from "react-icons/hi";
import "../styles/TemporaryRecords.component.scss";
import { LordIcon } from "./icons/LordIcon";
import { PiLinkSimpleHorizontalLight } from "react-icons/pi";
import { FiGlobe, FiFileText } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
    addFilesToActiveRoomFileList,
    selectActiveRoomFileList,
    selectActiveRoomId
} from "../store/slices/rooms.slice";
import { useState } from "react";
import { getFileLink } from "../utils/collaborate/parser";
import { type RoomMessageWithLoading } from "../store/slices/message.slice";
import { formatDate } from "../utils/date.utils";
import { extractLinks } from "../utils/strings.utils";
import { Button } from "@chakra-ui/react";
import { CiBoxList } from "react-icons/ci";
import { useHttpClient } from "../utils/http.utils";
import toast from "react-hot-toast";

interface FileOrLinkWithDate {
    type: "file" | "link";
    filename?: string;
    link: string;
    date: number;
}

export function TemporaryRecords() {
    const { listFileApi } = useHttpClient();
    const activeRoomFileList = useSelector(selectActiveRoomFileList);
    const messageList: RoomMessageWithLoading[] = useSelector(
        (state: any) => state.messages
    );
    const [displayMode, setDisplayMode] = useState<"files" | "links" | "all">(
        "all"
    );
    const dispatch = useDispatch();
    const [displayPage, setDisplayPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const activeRoomId = useSelector(selectActiveRoomId);

    const fetchFileList = async () => {
        try {
            setIsLoading(true);
            const fileList = await listFileApi(
                { room: activeRoomId },
                { page: displayPage }
            );

            dispatch(addFilesToActiveRoomFileList(fileList));
            setDisplayPage(displayPage + 1);
        } catch (error) {
            console.error(error);
            toast.error("Failed to load more files");
        } finally {
            setIsLoading(false);
        }
    };

    const getParsedLinkList = (): FileOrLinkWithDate[] => {
        const returnList: FileOrLinkWithDate[] = [];

        messageList.forEach(({ message }) => {
            returnList.push(
                ...(
                    extractLinks(message.message_data) || []
                ).map<FileOrLinkWithDate>(linkText => ({
                    type: "link",
                    link: linkText,
                    date: message.createdAt!
                }))
            );
        });

        return returnList;
    };

    const toggleFilesDisplayMode = () => {
        if (displayMode === "files") {
            setDisplayMode("all");
        } else {
            setDisplayMode("files");
        }
    };

    const toggleLinksDisplayMode = () => {
        if (displayMode === "links") {
            setDisplayMode("all");
        } else {
            setDisplayMode("links");
        }
    };

    const getFileOrLinkList = (): FileOrLinkWithDate[] => {
        const formattedLinkList: FileOrLinkWithDate[] =
            displayMode === "files" ? [] : getParsedLinkList();
        const formattedRoomFileList: FileOrLinkWithDate[] =
            displayMode === "links"
                ? []
                : activeRoomFileList.map(fileObject => ({
                      type: "file",
                      filename: fileObject.name,
                      link: getFileLink(),
                      date: fileObject.createdAt
                  }));

        return formattedLinkList
            .concat(formattedRoomFileList)
            .sort(
                (fileOrLink1, fileOrLink2) =>
                    fileOrLink2.date - fileOrLink1.date
            );
    };

    return (
        <div className="brain-activity-arin h-full temporary-records flex flex-col relative">
            <div className="brain-activity-header flex items-center justify-between">
                <div className="leading flex gap-1 items-center p-1 bg-white">
                    <span className="title">Records</span>
                </div>
                <div className="trailing chakra-styled flex p-1 bg-white gap-2">
                    <Button
                        colorScheme={displayMode === "files" ? "blue" : "gray"}
                        onClick={toggleFilesDisplayMode}
                        variant="outline"
                    >
                        <LordIcon
                            src="https://cdn.lordicon.com/nocovwne.json"
                            trigger="hover"
                            colors={{
                                primary: "#121331",
                                secondary: "#333"
                            }}
                            stroke={40}
                            size={24}
                        />
                    </Button>
                    <Button
                        onClick={toggleLinksDisplayMode}
                        colorScheme={displayMode === "links" ? "blue" : "gray"}
                        variant="outline"
                    >
                        <PiLinkSimpleHorizontalLight size="24px" />
                    </Button>
                </div>
            </div>
            <div className="file-body px-4">
                {getFileOrLinkList().length === 0 && (
                    <div className="empty-state">
                        <div className="flex flex-col items-center">
                            <CiBoxList size={"45px"} />
                            <p className="text-slate-500 mt-2">
                                No Records to display
                            </p>
                        </div>
                    </div>
                )}
                {getFileOrLinkList().map((fileOrLink, index) => (
                    <div key={index} className="file-item">
                        {fileOrLink.type === "file" ? (
                            <div className="icon">
                                <FiFileText className="icon" size="24px" />
                            </div>
                        ) : (
                            <div className="icon">
                                <FiGlobe className="icon" size="24px" />
                            </div>
                        )}
                        <div className="meta truncate">
                            {fileOrLink.type === "file" ? (
                                <h1
                                    className="name truncate"
                                    title={fileOrLink.filename}
                                >
                                    {fileOrLink.filename!}
                                </h1>
                            ) : (
                                <h1
                                    className="name truncate"
                                    title={fileOrLink.link}
                                >
                                    <a
                                        target="_blank"
                                        href={fileOrLink.link}
                                        rel="noreferrer"
                                    >
                                        {fileOrLink.link}
                                    </a>
                                </h1>
                            )}
                            <p className="date text-slate-500 text-[13px]">
                                {formatDate(fileOrLink.date, "D MMMM YYYY")}
                            </p>
                        </div>
                        <div className="action with-hidden-link">
                            {fileOrLink.type === "file" && (
                                <>
                                    <HiOutlineDownload
                                        className="icon"
                                        size="24px"
                                    />
                                    <a
                                        target="_blank"
                                        href={fileOrLink.link}
                                        download={fileOrLink.filename!}
                                        rel="noreferrer"
                                        className="hidden-link"
                                    ></a>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {activeRoomFileList.length > 49 && (
                <div className="absolute bottom-0 left-0 right-0 px-4 py-2 flex justify-center z-30">
                    <Button
                        size={"xs"}
                        variant="outline"
                        isLoading={isLoading}
                        onClick={fetchFileList}
                    >
                        Load More
                    </Button>
                </div>
            )}
        </div>
    );
}
