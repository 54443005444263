import { type PaymentIntent } from "@stripe/stripe-js";

export function showPaymentIntentStatusMessage(
    status: PaymentIntent.Status
): string {
    switch (status) {
        case "canceled":
            return "Your order was cancelled";
        case "processing":
            return "Your order is being processed. We will notify you once the payment is completed.";
        case "requires_capture":
            return "";
        case "requires_confirmation":
            return "Please confirm payment";
        case "requires_payment_method":
            return "We are sorry, there was an error processing your payment. Please try again with a different payment method";
        default:
            return "";
    }
}
